import { HiClock } from "react-icons/hi2";
import { MdPlace, MdRealEstateAgent, MdSchool } from "react-icons/md";
import { BiBuildings } from "react-icons/bi";

export const AUTHENTICATION_TOKEN = "token";
export const USER_ID = "userId";
export const PROPERTY_TO_LIKE = "property_to_like";
export const CARDS_ONBOARDING = "cardsOnboardingComplete";
export const ONBOARDING_SLIDESHOW = "onboardingSlideshowComplete";
export const EDIT_PROFILE_PROMPT_LAST_SHOWN = "editProfilePromptLastShown";
export const FIRST_PHOTO_PROMPT_LAST_SHOWN = "firstPhotoPromptLastShown";
export const HORIZONTAL_CARDS_SLIDESHOW = "horizontalCardsSlideShowComplete";
export const LAST_SERVICE_WORKER_TRIGGERED_RELOAD_TIME =
  "lastServiceWorkerTriggeredReloadTime";
export const SHOW_CONVERSATION_SUCCESS = "show_conversation_success";
export const MESSAGE_REFRESH_TIME = 3000;
export const MAX_HOUSE_PRICE = 5000000000;
export const HAS_OPENED_SEARCH_PANEL = "hasOpenedSearchPanel";

export const SIGNIN_MODAL_TYPE = {
  CHAT: "chat",
  PROPERTY: "property",
  FAVORITE: "favorite",
};
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const FAVORITE_BUTTON_ACTION = {
  LIKE: "like",
  UNLIKE: "unlike",
};

export const UPLOAD_PHOTO_TYPE = {
  PROPERTY_COVER: "propertycoverphotos",
  USER_PROFILE: "userprofilephotos",
  CLAIMED_PROPERTY_PHOTOS: "claimed_properties_photos",
};

export const IMAGE_SIZE_LIMIT = 10;

export const PLACES = [
  {
    rows: [
      {
        id: "1_20625116",
        type: "1",
        name: "4343 Kissena Blvd #411",
        subName: "Flushing, NY, USA",
        url: "/NY/Flushing/4343-Kissena-Blvd-11355/unit-411/home/20625116",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
      },
    ],
    name: "Recent Search",
  },
  {
    rows: [
      {
        id: "11_18",
        type: "11",
        name: "North Carolina",
        subName: "USA",
        url: "/state/North-Carolina",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 7953,
      },
      {
        id: "2_3105",
        type: "2",
        name: "Charlotte",
        subName: "Charlotte, NC, USA",
        url: "/city/3105/NC/Charlotte",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 43684,
      },
      {
        id: "2_35711",
        type: "2",
        name: "Raleigh",
        subName: "Raleigh, NC, USA",
        url: "/city/35711/NC/Raleigh",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 34382,
      },
      {
        id: "6_383909",
        type: "6",
        name: "North Carolina Central University",
        subName: "Durham, NC, USA",
        url: "/neighborhood/383909/NC/Durham/North-Carolina-Central-University",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 24,
      },
      {
        id: "2_35713",
        type: "2",
        name: "Cary",
        subName: "Cary, NC, USA",
        url: "/city/35713/NC/Cary",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 19247,
      },
      {
        id: "2_4909",
        type: "2",
        name: "Durham",
        subName: "Durham, NC, USA",
        url: "/city/4909/NC/Durham",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 16638,
      },
      {
        id: "2_555",
        type: "2",
        name: "Asheville",
        subName: "Asheville, NC, USA",
        url: "/city/555/NC/Asheville",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
        internalSearchVolume: 13827,
      },
    ],
    name: "Places",
  },
  {
    rows: [
      {
        id: "3_216882",
        type: "3",
        name: "North East Carolina Preparatory",
        subName: "Tarboro, NC, USA",
        url: "/school/216882/NC/Tarboro/North-East-Carolina-Preparatory",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
      },
      {
        id: "3_232990",
        type: "3",
        name: "North Carolina Cyber Academy",
        subName: "Durham, NC, USA",
        url: "/school/232990/NC/Durham/North-Carolina-Cyber-Academy",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
      },
      {
        id: "27_8464",
        type: "27",
        name: "Buncombe County Schools",
        url: "/school-district/8464/NC/Buncombe-County-Schools",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [69, 21, 124, 20],
        countryCode: "US",
      },
    ],
    name: "Schools",
  },
  {
    rows: [
      {
        id: "10_383909",
        type: "10",
        name: "North Carolina Central University Agents",
        subName: "Durham, NC, USA",
        url: "/neighborhood/383909/NC/Durham/North-Carolina-Central-University/real-estate/agents",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
      },
      {
        id: "10_764972",
        type: "10",
        name: "University of North Carolina Wilmington Agents",
        subName: "Wilmington, NC, USA",
        url: "/neighborhood/764972/NC/Wilmington/University-of-North-Carolina-Wilmington/real-estate/agents",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
      },
      {
        id: "10_374166",
        type: "10",
        name: "North Carolina School of the Arts Agents",
        subName: "Winston-Salem, NC, USA",
        url: "/neighborhood/374166/NC/Winston-Salem/North-Carolina-School-of-the-Arts/real-estate/agents",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
      },
    ],
    name: "Agents",
  },
  {
    rows: [
      {
        id: "16_10385",
        type: "16",
        name: "100 S North Carolina Ave Atlantic City, NJ, 08401",
        subName: "100 S North Carolina Ave, Atlantic City, NJ, USA",
        url: "/NJ/Atlantic-City/100-S-North-Carolina-Ave-Atlantic-City-NJ-08401/building/10385",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
      },
      {
        id: "16_56430",
        type: "16",
        name: "116 N Carolina Ave SE, Washington, DC 20003",
        subName: "116 N Carolina Ave SE, Washington, DC, DC, USA",
        url: "/DC/Washington-DC/116-N-Carolina-Ave-SE-Washington-DC-20003/building/56430",
        urlV2:
          "/DC/Washington-DC/116-N-Carolina-Ave-SE-Washington-DC-20003/building/56430",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
        hasRental: false,
      },
      {
        id: "16_49008",
        type: "16",
        name: "101 N Carolina Ave SE, Washington, DC 20003",
        subName: "101 N Carolina Ave SE, Washington, DC, DC, USA",
        url: "/DC/Washington-DC/101-N-Carolina-Ave-SE-Washington-DC-20003/building/49008",
        urlV2:
          "/DC/Washington-DC/101-N-Carolina-Ave-SE-Washington-DC-20003/building/49008",
        active: true,
        claimedHome: false,
        invalidMRS: false,
        businessMarketIds: [66, 17],
        countryCode: "US",
        hasRental: false,
      },
    ],
    name: "Buildings",
  },
];

export const PLACE_ICONS = {
  "Recent Search": HiClock,
  Places: MdPlace,
  Schools: MdSchool,
  Agents: MdRealEstateAgent,
  Buildings: BiBuildings,
};
