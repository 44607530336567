import React, { useRef, useState, useEffect } from "react";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { motion, AnimatePresence } from "framer-motion";

function HorizontalScrollDiv({ className, children }) {
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleScrollUpdate = () => {
    const { current } = scrollContainerRef;
    if (current) {
      setCanScrollLeft(current.scrollLeft > 0);
      setCanScrollRight(
        current.scrollLeft < current.scrollWidth - current.clientWidth,
      );
    }
  };

  const handleScroll = (direction) => {
    const { current } = scrollContainerRef;
    if (current) {
      const scrollAmount =
        direction === "left" ? -current.clientWidth : current.clientWidth;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const updateScrollButtons = () => {
      const { current } = scrollContainerRef;
      if (current) {
        setCanScrollLeft(current.scrollLeft > 0);
        setCanScrollRight(
          current.scrollLeft < current.scrollWidth - current.clientWidth,
        );
      }
    };

    updateScrollButtons();
    window.addEventListener("resize", updateScrollButtons);

    return () => {
      window.removeEventListener("resize", updateScrollButtons);
    };
  }, []);

  return (
    <div className={`relative ${className}`}>
      <AnimatePresence>
        {canScrollLeft && (
          <motion.button
            initial={{ opacity: 0, x: "-100%", y: "-50%" }}
            animate={{ opacity: 1, x: 0, y: "-50%" }}
            exit={{ opacity: 0, x: "-100%", y: "-50%" }}
            whileHover={{ width: "35px" }}
            transition={{ ease: "circInOut" }}
            className="group absolute right-[calc(100%-2.5rem)] top-1/2 z-20 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white p-1.5 shadow-lg shadow-neutral-500/30 ring-1 ring-neutral-300"
            onClick={() => handleScroll("left")}
          >
            <MdArrowBack className="h-4 w-4 text-neutral-800" />
          </motion.button>
        )}
      </AnimatePresence>

      <div
        className={`hide-scrollbar flex w-full overflow-x-auto`}
        ref={scrollContainerRef}
        onScroll={handleScrollUpdate}
      >
        {children}
      </div>
      <AnimatePresence>
        {canScrollRight && (
          <motion.button
            initial={{ opacity: 0, x: "100%", y: "-50%" }}
            animate={{ opacity: 1, x: 0, y: "-50%" }}
            exit={{ opacity: 0, x: "100%", y: "-50%" }}
            whileHover={{ width: "35px" }}
            transition={{ ease: "circInOut" }}
            className="group absolute left-[calc(100%-2.5rem)] top-1/2 z-20 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white p-1.5 shadow-lg shadow-neutral-500/30 ring-1 ring-neutral-300"
            onClick={() => handleScroll("right")}
          >
            <MdArrowForward className="h-4 w-4 text-neutral-800" />
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
}

export default HorizontalScrollDiv;
