import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";

import {
  getUsersLikedHomes,
  loadInitialData,
  updateUserDetails,
  verifyUser,
} from "./service";
import {
  AUTHENTICATION_TOKEN,
  CARDS_ONBOARDING,
  ONBOARDING_SLIDESHOW,
  USER_ID,
  EDIT_PROFILE_PROMPT_LAST_SHOWN,
  FIRST_PHOTO_PROMPT_LAST_SHOWN,
  HORIZONTAL_CARDS_SLIDESHOW,
  HAS_OPENED_SEARCH_PANEL,
} from "@src/utils/constants";
import { version } from "@src/../package.json";
import { Capacitor } from "@capacitor/core";
import { Preferences } from "@capacitor/preferences";

const initialState = {
  user: {},
  homes: [],
  likedHomes: [],
  hasMoreLikedHomes: true,
  isLoadingLikedHomes: false,
  numberOfHomesUnliked: 0,
  isLoggedIn: false,
  isLoading: false,
  isUpdatingUserDetails: false,
  token: null,
  appVersion: version,
  // eslint-disable-next-line no-undef
  appBuildDate: __BUILD_DATE__,
  isRunningServiceWorker: false,
  isRunningInStandAlone:
    window.navigator.standalone ||
    window.matchMedia("(display-mode: standalone)").matches,
  isRunningInNativeApp: Capacitor.isNativePlatform(),
  cardsOnboardingComplete:
    JSON.parse(localStorage.getItem(CARDS_ONBOARDING)) || false,
  onboardingSlideshowComplete:
    JSON.parse(localStorage.getItem(ONBOARDING_SLIDESHOW)) || false,
  editProfilePromptLastShown:
    localStorage.getItem(EDIT_PROFILE_PROMPT_LAST_SHOWN) || false,
  firstPhotoPromptLastShown:
    localStorage.getItem(FIRST_PHOTO_PROMPT_LAST_SHOWN) || null,
  hasOpenedSearchPanel: localStorage.getItem(HAS_OPENED_SEARCH_PANEL) || false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserToken: (state, { payload }) => {
      state.token = payload;
      state.isLoggedIn = true;
      Preferences.set({
        key: AUTHENTICATION_TOKEN,
        value: JSON.stringify(payload),
      });
    },

    resetTours: (state) => {
      Preferences.remove({ key: CARDS_ONBOARDING });
      Preferences.remove({ key: ONBOARDING_SLIDESHOW });
      Preferences.remove({ key: EDIT_PROFILE_PROMPT_LAST_SHOWN });
      Preferences.remove({ key: FIRST_PHOTO_PROMPT_LAST_SHOWN });
      Preferences.remove({ key: HORIZONTAL_CARDS_SLIDESHOW });
      Preferences.remove({ key: HAS_OPENED_SEARCH_PANEL });
      document.location.reload();
    },

    setHasOpenedSearchPanel: (state, { payload }) => {
      state.hasOpenedSearchPanel = payload;
      Preferences.set({
        key: HAS_OPENED_SEARCH_PANEL,
        value: JSON.stringify(payload),
      });
    },

    setCardsOnboardingComplete: (state, { payload }) => {
      state.cardsOnboardingComplete = payload;
      Preferences.set({ key: CARDS_ONBOARDING, value: payload });
    },

    setOnboardingSlideshowComplete: (state, { payload }) => {
      state.onboardingSlideshowComplete = payload;
      Preferences.set({ key: ONBOARDING_SLIDESHOW, value: payload });
    },

    setEditProfilePromptLastShown: (state, { payload }) => {
      state.editProfilePromptLastShown = payload;
      if (payload) {
        Preferences.set({
          key: EDIT_PROFILE_PROMPT_LAST_SHOWN,
          value: payload,
        });
      }
    },

    setFirstPhotoPromptLastShown: (state, { payload }) => {
      state.firstPhotoPromptLastShown = payload;
      if (payload) {
        Preferences.set({ key: FIRST_PHOTO_PROMPT_LAST_SHOWN, value: payload });
      }
    },

    setUserInfo: (state, { payload }) => {
      state.homes = payload.properties.slice().reverse();
      state.user = payload;
      if (payload.id) {
        Preferences.set({ key: USER_ID, value: payload.id });
      }
      Sentry.setUser({
        email: payload.email,
        id: payload.id,
      });

      Hotjar.identify(payload.id, {
        email: payload.email,
      });

      ReactGA.gtag("set", "user_properties", {
        user_id: payload.id,
      });
    },

    removeUserInfo: (state) => {
      state.user = initialState.user;
      state.homes = initialState.homes;
      state.isLoggedIn = false;
      state.token = initialState.token;
      Preferences.remove({ key: AUTHENTICATION_TOKEN });
      Preferences.remove({ key: USER_ID });
      Preferences.remove({ key: EDIT_PROFILE_PROMPT_LAST_SHOWN });
    },
    setIsRunningServiceWorker: (state, { payload }) => {
      state.isRunningServiceWorker = payload;
      Sentry.setTag("isRunningServiceWorker", payload);
    },
    setIsRunningInNativeApp: (state, { payload }) => {
      state.isRunningInNativeApp = payload;
      Sentry.setTag("isRunningInNativeApp", payload);
    },
    changeStatusInLikedHome: (state, { payload }) => {
      const index = state.likedHomes.findIndex(
        (item) => item?.id === payload.propertyId,
      );
      if (state.likedHomes[index]) {
        if (!payload.liked) {
          state.numberOfHomesUnliked += 1;
        } else {
          state.numberOfHomesUnliked -= 1;
        }

        state.likedHomes[index] = {
          ...state.likedHomes[index],
          liked: payload.liked,
        };
      }
    },
    resetNumberOfHomesUnliked: (state) => {
      state.numberOfHomesUnliked = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyUser.fulfilled, (state, { payload }) => {
        state.token = payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
        Preferences.set({
          key: AUTHENTICATION_TOKEN,
          value: JSON.stringify(payload.token),
        });
        state.user = { ...state.user, ...payload.userInfo };
        if (payload.userInfo?.id) {
          Preferences.set({ key: USER_ID, value: payload.userInfo.id });
        }
      })
      .addCase(verifyUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUsersLikedHomes.pending, (state) => {
        state.isLoadingLikedHomes = true;
      })
      .addCase(
        getUsersLikedHomes.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.isLoadingLikedHomes = false;
          if (arg.offset === 0) {
            state.likedHomes = payload.properties;
          } else {
            state.likedHomes = [...state.likedHomes, ...payload.properties];
          }

          if (
            state.likedHomes.length - state.numberOfHomesUnliked >=
            payload.count
          ) {
            state.hasMoreLikedHomes = false;
          } else state.hasMoreLikedHomes = true;
        },
      )
      .addCase(getUsersLikedHomes.rejected, (state) => {
        state.isLoadingLikedHomes = false;
      })
      .addCase(updateUserDetails.pending, (state) => {
        state.isUpdatingUserDetails = true;
      })
      .addCase(updateUserDetails.fulfilled, (state, { payload }) => {
        state.user = { ...state.user, ...payload };
        state.isUpdatingUserDetails = false;
      })
      .addCase(updateUserDetails.rejected, (state) => {
        state.isUpdatingUserDetails = false;
      })
      .addCase(loadInitialData.fulfilled, (state, { payload }) => {
        state.cardsOnboardingComplete = payload.cardsOnboardingComplete;
        state.onboardingSlideshowComplete = payload.onboardingSlideshowComplete;
        state.editProfilePromptLastShown = payload.editProfilePromptLastShown;
        state.firstPhotoPromptLastShown = payload.firstPhotoPromptLastShown;
        state.horizontalCardsSlideShowComplete =
          payload.horizontalCardsSlideShowComplete;
        state.hasOpenedSearchPanel = payload.hasOpenedSearchPanel;
      });
  },
});

// Define the checkAppPlatformCookie function
const checkAppPlatformCookie = () => {
  if (typeof document !== "undefined") {
    const cookies = document.cookie.split("; ");
    const appPlatformCookie = cookies.find((cookie) =>
      cookie.startsWith("app-platform="),
    );
    if (appPlatformCookie) {
      const value = appPlatformCookie.split("=")[1];
      return value === "iOS App Store";
    }
  }
  return false;
};

export const doesUserOwnProperty = (state, propertyId) => {
  return state.user.homes.some((home) => home.id === propertyId);
};

export const {
  setUserToken,
  setUserInfo,
  removeUserInfo,
  changeStatusInLikedHome,
  resetNumberOfHomesUnliked,
  setIsRunningServiceWorker,
  setIsRunningInNativeApp,
  setCardsOnboardingComplete,
  setOnboardingSlideshowComplete,
  resetTours,
  setEditProfilePromptLastShown,
  setFirstPhotoPromptLastShown,
  setHasOpenedSearchPanel,
} = userSlice.actions;
export default userSlice.reducer;
