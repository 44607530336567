import React from "react";

function AddAPhotoIcon({ className }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      width="24px"
      fill="currentColor"
    >
      <path d="M440-440ZM144.62-160Q117-160 98.5-178.5 80-197 80-224.62v-430.76Q80-683 98.5-701.5 117-720 144.62-720h118.3l74-80h207.7v40H354.23l-73.77 80H144.62q-10.77 0-17.7 6.92-6.92 6.93-6.92 17.7v430.76q0 10.77 6.92 17.7 6.93 6.92 17.7 6.92h590.76q10.77 0 17.7-6.92 6.92-6.93 6.92-17.7v-320h40v320q0 27.62-18.5 46.12Q763-160 735.38-160H144.62ZM760-680v-80h-80v-40h80v-80h40v80h80v40h-80v80h-40ZM440-290.77q62.69 0 105.96-43.27 43.27-43.27 43.27-105.96 0-62.69-43.27-105.96-43.27-43.27-105.96-43.27-62.69 0-105.96 43.27-43.27 43.27-43.27 105.96 0 62.69 43.27 105.96 43.27 43.27 105.96 43.27Zm0-40q-46.62 0-77.92-31.31-31.31-31.3-31.31-77.92 0-46.62 31.31-77.92 31.3-31.31 77.92-31.31 46.62 0 77.92 31.31 31.31 31.3 31.31 77.92 0 46.62-31.31 77.92-31.3 31.31-77.92 31.31Z" />
    </svg>
  );
}

export default AddAPhotoIcon;
