import React, { Fragment, useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { AnimatePresence, motion } from "framer-motion";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SelectArchitecturalStyles from "@src/components/selectArchitecturalStyles/SelectArchitecturalStyles.jsx";
import edit_screens from "@src/data/edit_screens.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import UpdatePropertyProfilePerks from "@src/components/updatePropertyProfilePerks/UpdatePropertyProfilePerks.jsx";
import { MdArrowBack, MdArrowForward, MdCheck, MdClose } from "react-icons/md";
import RadioGroupInput from "@src/components/radioGroupInput/RadioGroupInput.jsx";
import ownership_cycle from "@src/data/ownership_cycle.js";
import selling_choices from "@src/data/selling_choices.js";
import PropertyProfileTextSliderInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileTextSliderInput/PropertyProfileTextSliderInput.jsx";
import { updateProperty } from "@src/store/features/property/service.js";
import price_range from "@src/data/price_range";
import PropertyProfileFirstPhotoInput from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileFirstPhotoInput/PropertyProfileFirstPhotoInput.jsx";

function PropertyProfileEditScreensModal({ property }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const { onboardingSlideshowComplete, isLoggedIn, homes } = useSelector(
    (state) => state.user,
  );

  const isEditingProfile = searchParams.get("edit");
  const isShowingEditScreens = searchParams.get("edit_screens");
  const isHomeOwner =
    Array.isArray(homes) && homes.length
      ? homes.some((home) => home.id === property.id)
      : false;
  const [editedScreens, setEditedScreens] = useState([]);

  const inputComponents = {
    cover_photo_uuid: () => (
      <div className="flex flex-col items-center justify-center">
        <div className="relative aspect-video h-auto w-[min(100%-1rem,32rem)] overflow-hidden rounded-lg">
          <PropertyProfileFirstPhotoInput
            property={property}
            onChange={(uuid) => {
              setEditedScreens((prev) => [...prev, "cover_photo_uuid"]);

              dispatch(
                setPropertyPropValue({
                  key: "cover_photo_uuid",
                  value: uuid,
                }),
              );

              //   await dispatch(
              //     updateProperty({ propertyData: property, isDraft: true }),
              //   );
              //
              //
              // dispatch(
              //   setPropertyPropValue({
              //     key: "cover_photo_uuid",
              //     value: uuid,
              //   }),
              // );
            }}
          />
        </div>
      </div>
    ),
    owner_given_style: () => (
      <SelectArchitecturalStyles
        prefix="edit_screen"
        value={[property.owner_given_style] || [property.style] || []}
        onChange={(styles, writeIn) => {
          setEditedScreens((prev) => [...prev, "owner_given_style"]);
          dispatch(
            setPropertyPropValue({
              key: "owner_given_style_writein",
              value: writeIn,
            }),
          );
          dispatch(
            setPropertyPropValue({
              key: "owner_given_style",
              value: styles[0],
            }),
          );
        }}
      />
    ),
    owner_perks: () => (
      <UpdatePropertyProfilePerks
        property={property}
        prefix="edit_screen"
        onChange={() => setEditedScreens((prev) => [...prev, "owner_perks"])}
      />
    ),
    owner_ownership_cycle: () => (
      <RadioGroupInput
        value={property.owner_ownership_cycle}
        options={ownership_cycle}
        onChange={(newValue) => {
          setEditedScreens((prev) => [...prev, "owner_ownership_cycle"]);

          dispatch(
            setPropertyPropValue({
              key: "owner_ownership_cycle",
              value: newValue,
            }),
          );
        }}
      />
    ),
    owner_selling_choice: () => (
      <RadioGroupInput
        value={property.owner_selling_choice}
        options={selling_choices}
        onChange={(newValue) => {
          setEditedScreens((prev) => [...prev, "owner_selling_choice"]);

          dispatch(
            setPropertyPropValue({
              key: "owner_selling_choice",
              value: newValue,
            }),
          );
        }}
      />
    ),
    owner_expected_value: () => (
      <PropertyProfileTextSliderInput
        prop={{
          value: property.owner_expected_value,
          defaultValue:
            property.estimated_value || property.estimated_value_one || 0,
          icon: null,
          labelSingular: "",
          labelPlural: "",
          isCurrency: "$",
          min: 10000,
          max: 10000000,
          maxSliderValue: price_range?.values?.length - 1,
          interval: 25,
          roundTo: 25,
          isInputWithSlider: true,
          placeholder: "Estimated value",
          values: price_range?.values,
          steps: price_range?.steps,
        }}
        onChange={async (value) => {
          setEditedScreens((prev) => [...prev, "owner_expected_value"]);
          await new Promise((resolve) => setTimeout(resolve, 20));
          dispatch(
            setPropertyPropValue({ key: "owner_expected_value", value }),
          );
        }}
      />
    ),
  };

  const screensToEdit = edit_screens
    .filter(
      (screen) =>
        editedScreens.includes(screen.owner_id) ||
        (!property[screen.owner_id] && inputComponents[screen.owner_id]),
    )
    .map((screen) => {
      return { ...screen, inputComponent: inputComponents[screen.owner_id] };
    });

  const [currentScreenIndex, setCurrentScreenIndex] = useState(0);

  const [editCount, setEditCount] = useState(
    edit_screens.filter(
      (screen) => editedScreens[screen.owner_id] || property[screen.owner_id],
    ).length,
  );

  const [percentComplete, setPercentComplete] = useState(
    (editCount / edit_screens.length) * 100,
  );

  const [stepsComplete, setStepsComplete] = useState(
    100 / edit_screens.length +
      (currentScreenIndex / edit_screens.length) * 100,
  );

  const [progressOpacity, setProgressOpacity] = useState(0.75);

  const goToStep = async (step) => {
    if (step < 0) return;

    if (step >= screensToEdit.length) {
      dismissPrompt(true);
      return;
    } else {
      dispatch(updateProperty({ propertyData: property, isDraft: true }));
    }
    setCurrentScreenIndex(step);
  };

  const dismissPrompt = async (complete) => {
    dispatch(updateProperty({ propertyData: property, isDraft: false }));

    if (complete) {
      setOpen(false);
      setTimeout(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete("edit_screens");
        setSearchParams(newSearchParams);
      }, 300);
    }
  };

  useEffect(() => {
    if (isLoggedIn && isHomeOwner && isShowingEditScreens) {
      setOpen(true);
    }
  }, [searchParams, homes]);

  useEffect(() => {
    const newEditCount = edit_screens.filter(
      (screen) => editedScreens[screen.owner_id] || property[screen.owner_id],
    ).length;
    setEditCount(newEditCount);
    const percentComplete = (newEditCount / edit_screens.length) * 100;

    setPercentComplete(percentComplete);
    setProgressOpacity(percentComplete >= 100 ? 0 : 0.75);
  }, [property, editedScreens]);

  return (
    <>
      {isShowingEditScreens ? (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 z-40 bg-black/40 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
            <Dialog.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="fixed left-1/2 top-0 z-50 flex h-dvh w-dvw -translate-x-1/2 flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
            >
              <VisuallyHidden>
                <Dialog.Title>Please edit your profile</Dialog.Title>
                <Dialog.Description>
                  Click the button to launch the profile editor
                </Dialog.Description>
              </VisuallyHidden>
              <div className="absolute left-1/2 flex h-full w-dvw -translate-x-1/2 flex-col items-center justify-center overflow-hidden text-balance bg-[#FAFAFA] md:bottom-auto md:top-1/2 md:h-[calc(100%-5rem)] md:w-[min(calc(100vw-2rem),42rem)] md:-translate-y-1/2 md:rounded-3xl">
                <div className="flex h-full w-full flex-col">
                  <header className="relative z-30 flex select-none items-center justify-between border-b border-b-neutral-300 p-4 py-5 pb-4 font-content text-sm font-semibold text-neutral-500">
                    {currentScreenIndex > 0 ? (
                      <button onClick={() => goToStep(currentScreenIndex - 1)}>
                        <MdArrowBack className="h-6 w-6" />
                      </button>
                    ) : (
                      <div className="w-6"></div>
                    )}

                    <div className="flex flex-1 items-center justify-center gap-4 text-center tracking-tighter sm:tracking-normal">
                      {screensToEdit.map((screen, index) => (
                        <div
                          key={`label_${screen.owner_id}`}
                          className="group flex items-center"
                        >
                          <div
                            onClick={() => goToStep(index)}
                            className={`relative flex items-center justify-center ${index === currentScreenIndex ? "pointer-events-none h-3.5 w-3.5" : "h-1.5 w-1.5 md:cursor-pointer"} rounded-full ${editedScreens.includes(screen.owner_id) ? "bg-primary-500/90 ring-1 ring-neutral-400 md:hover:bg-sage-700" : "ring-1 ring-neutral-400 md:hover:bg-neutral-200"} transition-all`}
                          >
                            <MdCheck
                              className={`${index === currentScreenIndex ? "opacity-100" : "opacity-0"} h-3 w-3 fill-white`}
                            />
                            <div
                              className={`absolute ${index === currentScreenIndex ? "hidden" : ""} ${index - 1 === currentScreenIndex ? "block" : "hidden"} right-full top-1/2 h-[1px] w-4 -translate-y-1/2 transform bg-neutral-400 transition-all`}
                            ></div>
                            <div
                              className={`absolute ${index === currentScreenIndex ? "hidden" : ""} left-full top-1/2 h-[1px] w-4 -translate-y-1/2 transform bg-neutral-400 transition-all group-last:hidden`}
                            ></div>
                          </div>

                          <motion.div
                            initial={{
                              width:
                                index === currentScreenIndex
                                  ? "fit-content"
                                  : 0,
                            }}
                            animate={{
                              width:
                                index === currentScreenIndex
                                  ? "fit-content"
                                  : 0,
                            }}
                            className="relative overflow-hidden whitespace-nowrap"
                          >
                            <motion.div
                              initial={{
                                scale: index === currentScreenIndex ? 1 : 0,
                                // opacity: index === currentScreenIndex ? 1 : 0,
                              }}
                              animate={{
                                scale: index === currentScreenIndex ? 1 : 0,
                                // opacity: index === currentScreenIndex ? 1 : 0,
                              }}
                              className="px-2"
                            >
                              {screen.label}
                            </motion.div>
                          </motion.div>
                        </div>
                      ))}
                    </div>

                    <button onClick={() => dismissPrompt(true)}>
                      <MdClose className="h-6 w-6" />
                    </button>
                  </header>
                  <motion.div
                    initial={{
                      x: `${(currentScreenIndex / screensToEdit.length) * -100}%`,
                    }}
                    animate={{
                      x: `${(currentScreenIndex / screensToEdit.length) * -100}%`,
                    }}
                    transition={{ ease: "circInOut" }}
                    className="grid w-fit flex-1 grid-flow-col overflow-hidden"
                  >
                    {screensToEdit.map((screen, index) => (
                      <Fragment key={screen.owner_id}>
                        <div className="hide-scrollbar relative flex w-dvw flex-1 flex-col justify-center overflow-hidden md:w-[min(calc(100vw-2rem),42rem)]">
                          <div className="max-h-full overflow-y-auto p-8 pt-0">
                            <AnimatePresence>
                              {index === currentScreenIndex && (
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                  className="min-h-full"
                                >
                                  {screen.prompt?.title && (
                                    <p className="z-20 mx-auto max-w-[26ch] text-pretty bg-[#fafafa] pt-7 text-center font-attention text-2xl text-neutral-900 md:text-3xl">
                                      {screen.prompt.title}
                                    </p>
                                  )}
                                  {screen.prompt?.description && (
                                    <p className="mx-auto mt-6 max-w-[40ch] text-pretty text-center text-neutral-600">
                                      {screen.prompt.description}
                                    </p>
                                  )}
                                  <div className="mt-12">
                                    {screen.inputComponent()}
                                  </div>
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </motion.div>

                  <footer className="relative flex w-full items-center justify-center p-6 md:py-10">
                    <button
                      onClick={() => {
                        goToStep(currentScreenIndex + 1);
                      }}
                      className="group relative flex w-full max-w-xs items-center justify-center gap-2 overflow-hidden rounded-full bg-[#8E9991] p-3 px-8 text-lg text-white shadow-xl shadow-primary-500/20 transition-all sm:max-w-72 md:hover:bg-[#818d84]"
                    >
                      <motion.div
                        initial={{
                          x: `${percentComplete}%`,
                          opacity: progressOpacity,
                        }}
                        animate={{
                          x: `${percentComplete}%`,
                          opacity: progressOpacity,
                          transition: {
                            duration: 0.5,
                            ease: "circOut",
                          },
                        }}
                        className="diagonal-stripes opacity-75"
                      >
                        <div className="bg-diagonal-stripes"></div>
                      </motion.div>

                      <span className="relative">
                        {currentScreenIndex + 1 < screensToEdit.length
                          ? editedScreens.includes(
                              screensToEdit[currentScreenIndex].owner_id,
                            ) ||
                            property[screensToEdit[currentScreenIndex].owner_id]
                            ? "Next"
                            : "Skip"
                          : "Done"}
                      </span>
                      {currentScreenIndex + 1 < screensToEdit.length && (
                        <MdArrowForward className="relative h-5 w-5 transition-transform md:group-hover:translate-x-1/4" />
                      )}
                    </button>
                  </footer>
                </div>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      ) : null}
    </>
  );
}

export default PropertyProfileEditScreensModal;
