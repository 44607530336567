import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import {
  MdArrowBack,
  MdArrowForward,
  MdChevronLeft,
  MdClose,
  MdFileUpload,
} from "react-icons/md";
import HorizontalScrollDiv from "@src/components/horizontalScrollDiv/HorizontalScrollDiv.jsx";
import { useSearchParams } from "react-router-dom";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import PropertyProfileHeadline from "@src/components/propertyProfile/propertyProfileHeadline/PropertyProfileHeadline.jsx";
import PropertyProfileSubheading from "@src/components/propertyProfile/propertyProfileSubheading/PropertyProfileSubheading.jsx";
import PropertyProfileDetails from "@src/components/propertyProfile/propertyProfileDetails/PropertyProfileDetails.jsx";
import { IoMdPricetag, IoMdTrash } from "react-icons/io";
import { LuStar, LuTag, LuTrash } from "react-icons/lu";
import { deleteClaimedPropertyPhoto } from "@src/utils/photos.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import { updateProperty } from "@src/store/features/property/service.js";
import { useDispatch, useSelector } from "react-redux";
import { cacheImage } from "@src/store/features/imageCache/slice.js";
import Spinner from "@src/components/spinner/Spinner.jsx";

function PropertyProfileImageCarousel({ property }) {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState(property.photos || []);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const controls = useAnimation();
  const imageScrollerRef = useRef(null);
  const imageThumbScrollerRef = useRef(null);
  const initialRender = useRef(true);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const loggedInUser = useSelector((state) => state.user.user);
  const homes = useSelector((state) => state.user.homes);

  useEffect(() => {
    if (searchParams.get("photo_id")) {
      const photoIndex = photos.findIndex(
        (photo) => photo.uuid === searchParams.get("photo_id"),
      );
      setCurrentPhotoIndex(photoIndex);
      if (initialRender.current) {
        //scroll to the selected photo
        setTimeout(() => {
          if (imageScrollerRef.current) {
            const targetImage = imageScrollerRef.current.children[photoIndex];
            if (targetImage) {
              targetImage.scrollIntoView({
                behavior: "instant",
                inline: "center",
              });
            } else {
              setOpen(false);
            }
          }
        }, 100);

        initialRender.current = false;
      }
    }

    if (searchParams.get("viewing_photos")) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [searchParams]);

  useEffect(() => {
    setPhotos(property.photos || []);
  }, [property.photos, property.cover_photo_uuid]);

  const handleClose = () => {
    setOpen(false);
    initialRender.current = true;
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.delete("viewing_photos");
    currentSearchParams.delete("photo_id");
    setSearchParams(currentSearchParams);
  };

  const handleThumbnailClick = (index) => {
    //setCurrentPhotoIndex(index);

    //get photo uuid and set it in the search params
    const currentSearchParams = new URLSearchParams(searchParams);
    currentSearchParams.set("photo_id", photos[index].uuid);
    setSearchParams(currentSearchParams);

    if (imageScrollerRef.current) {
      const targetImage = imageScrollerRef.current.children[index];
      targetImage.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  };

  const handleScroll = useCallback(() => {
    if (imageScrollerRef.current) {
      const scrollPosition = imageScrollerRef.current.scrollLeft;
      const scrollerWidth = imageScrollerRef.current.clientWidth;

      // Determine the visible image index
      const visibleIndex = Math.round(scrollPosition / scrollerWidth);
      setCurrentPhotoIndex(visibleIndex);

      // Update the URL with the new photo ID
      // const currentPhotoId = photos[visibleIndex]?.uuid;
      // if (currentPhotoId && currentPhotoId !== searchParams.get("photo_id")) {
      //   // const currentSearchParams = new URLSearchParams(searchParams);
      //   // currentSearchParams.set("photo_id", currentPhotoId);
      //   // setSearchParams(currentSearchParams);
      // }

      // Scroll the thumbnail scroller to center the selected thumbnail
      if (imageThumbScrollerRef.current) {
        const targetThumbnail =
          imageThumbScrollerRef.current.children[visibleIndex];
        targetThumbnail.scrollIntoView({
          behavior: "smooth",
          inline: "nearest",
        });
      }

      handleScrollUpdate();
    }
  }, [photos, searchParams, setSearchParams]);

  const handleScrollUpdate = () => {
    const { current } = imageScrollerRef;
    if (current) {
      setCanScrollLeft(current.scrollLeft > 0);
      setCanScrollRight(
        current.scrollLeft < current.scrollWidth - current.clientWidth,
      );
    }
  };

  const handleScrollClick = (direction) => {
    const { current } = imageScrollerRef;
    if (current) {
      const scrollAmount =
        direction === "left" ? -current.clientWidth : current.clientWidth;
      current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handleDeleteImage = async (uuid) => {
    // get the image based on the uuid
    const uuidOfPhotoToRemove = photos[currentPhotoIndex].uuid;
    const uuidOfPhotoToShow =
      photos[currentPhotoIndex - 1]?.uuid ||
      photos[currentPhotoIndex + 1]?.uuid;

    setIsDeleting(true);
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      await deleteClaimedPropertyPhoto(uuidOfPhotoToRemove);
      const propertyPhotos = property.photos?.filter(
        (photo) => photo.uuid !== uuidOfPhotoToRemove,
      );

      await dispatch(
        setPropertyPropValue({
          key: "photos",
          value: propertyPhotos,
        }),
      );

      if (uuidOfPhotoToRemove === property.cover_photo_uuid) {
        await dispatch(
          cacheImage({
            propertyId: property.id,
            imageUrl: null,
          }),
        );

        await dispatch(
          setPropertyPropValue({
            key: "cover_photo_uuid",
            value: null,
          }),
        );
        await dispatch(
          setPropertyPropValue({
            key: "cover_photo_url",
            value: null,
          }),
        );
      }

      property = {
        ...property,
        photos: propertyPhotos,
        cover_photo_url: null,
        cover_photo_uuid: null,
      };

      await dispatch(updateProperty({ propertyData: property, isDraft: true }));

      setIsDeleting(false);
      if (uuidOfPhotoToShow) {
        const currentSearchParams = new URLSearchParams(searchParams);
        currentSearchParams.set("photo_id", uuidOfPhotoToShow);
        setSearchParams(currentSearchParams);
      } else {
        setOpen(false);
        const currentSearchParams = new URLSearchParams(searchParams);
        currentSearchParams.delete("photo_id");
        currentSearchParams.delete("viewing_photos");
        setSearchParams(currentSearchParams);
      }
    } catch (error) {
      console.error("Error updating photos:", error);
    }
  };

  const handleToggleCoverPhotoClick = async (uuid) => {
    let uuidOfPhoto = photos[currentPhotoIndex].uuid;

    if (uuidOfPhoto === property.cover_photo_uuid) {
      uuidOfPhoto = null;
      await dispatch(
        cacheImage({
          propertyId: property.id,
          imageUrl: null,
        }),
      );
    }

    await dispatch(
      setPropertyPropValue({
        key: "cover_photo_uuid",
        value: uuidOfPhoto,
      }),
    );

    await dispatch(
      setPropertyPropValue({
        key: "cover_photo_url",
        value: null,
      }),
    );

    property = {
      ...property,
      cover_photo_uuid: uuidOfPhoto,
      cover_photo_url: null,
    };

    await dispatch(updateProperty({ propertyData: property, isDraft: true }));
  };

  useEffect(() => {
    setTimeout(() => {
      if (imageScrollerRef.current) {
        handleScroll();
      }
    }, 10);
  }, []);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-50" />
        <Dialog.Content
          onOpenAutoFocus={(e) => e.preventDefault()}
          onPointerDownOutside={(e) => e.preventDefault()}
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(event) => {
            event.preventDefault();
          }}
          className="fixed left-1/2 top-0 z-50 flex w-dvw -translate-x-1/2 flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
        >
          <VisuallyHidden>
            <Dialog.Title>Photo Viewer</Dialog.Title>
            <Dialog.Description>Photos of property</Dialog.Description>
          </VisuallyHidden>
          <div className="flex h-dvh w-full flex-col overflow-hidden bg-neutral-100">
            <header className="flex w-full items-center gap-4 border-b border-b-neutral-200 p-5 py-3.5">
              <button
                onClick={handleClose}
                className="flex items-center justify-center rounded-full bg-primary-500/20 p-1 transition-all hover:bg-primary-500/40"
              >
                <MdChevronLeft className="h-6 w-6 text-neutral-500" />
              </button>
              <div className="flex flex-1 justify-center">
                <div className="flex flex-wrap items-center justify-center overflow-hidden">
                  <PropertyProfileHeadline
                    property={property}
                    className="sm:text-md font-attention text-xs font-light text-neutral-800 sm:text-xl"
                    inline={true}
                    shouldTruncate
                  />
                  <PropertyProfileSubheading
                    property={property}
                    inline={true}
                    className="truncate font-attention text-xs text-neutral-700 sm:text-xl"
                    shouldTruncate
                  />
                </div>
              </div>
            </header>
            <AnimatePresence>
              {canScrollLeft && (
                <motion.button
                  initial={{ opacity: 0, x: "-100%", y: "-50%" }}
                  animate={{ opacity: 1, x: 0, y: "-50%" }}
                  exit={{ opacity: 0, x: "-100%", y: "-50%" }}
                  whileHover={{ width: "35px" }}
                  transition={{ ease: "circInOut" }}
                  className="group absolute right-[calc(100%-2.5rem)] top-1/2 z-20 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white p-1.5 shadow-lg shadow-neutral-500/30 ring-1 ring-neutral-300"
                  onClick={() => handleScrollClick("left")}
                >
                  <MdArrowBack className="h-4 w-4 text-neutral-800" />
                </motion.button>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {canScrollRight && (
                <motion.button
                  initial={{ opacity: 0, x: "100%", y: "-50%" }}
                  animate={{ opacity: 1, x: 0, y: "-50%" }}
                  exit={{ opacity: 0, x: "100%", y: "-50%" }}
                  whileHover={{ width: "35px" }}
                  transition={{ ease: "circInOut" }}
                  className="group absolute left-[calc(100%-2.5rem)] top-1/2 z-20 flex h-7 w-7 -translate-y-1/2 items-center justify-center rounded-full bg-white p-1.5 shadow-lg shadow-neutral-500/30 ring-1 ring-neutral-300"
                  onClick={() => handleScrollClick("right")}
                >
                  <MdArrowForward className="h-4 w-4 text-neutral-800" />
                </motion.button>
              )}
            </AnimatePresence>
            <div
              ref={imageScrollerRef}
              onScroll={handleScroll}
              className="hide-scrollbar grid w-full flex-1 snap-x snap-mandatory grid-flow-col overflow-x-auto"
            >
              {photos.map((photo, index) => (
                <div
                  key={photo.uuid}
                  className="relative flex w-dvw snap-center flex-col justify-center overflow-hidden"
                >
                  <div className="relative h-full w-full p-5">
                    <div className="flex h-full w-full flex-col items-center justify-center">
                      <div className="flex max-h-full flex-col">
                        <div className="flex w-full flex-col flex-wrap items-center justify-between gap-2 p-2 sm:flex-row">
                          <div className="flex flex-1 items-center text-sm capitalize">
                            {photo.tags?.length > 0 && (
                              <>
                                <LuTag className="mr-1 h-3 w-3" />
                                <span className="px-1 text-neutral-500">
                                  {photo.tags.join(", ")}
                                </span>
                              </>
                            )}
                          </div>

                          {loggedInUser?.id === property?.owner_id && (
                            <div className="flex items-center gap-6">
                              <button
                                onClick={handleToggleCoverPhotoClick}
                                className={`flex items-center gap-1.5 text-nowrap rounded-md p-1.5 px-2.5 text-sm text-neutral-500 transition-all hover:bg-neutral-200 ${photo.uuid === property.cover_photo_uuid ? "fill-sky-500 stroke-sky-500 text-sky-500" : "fill-transparent stroke-neutral-500"}`}
                              >
                                <LuStar
                                  className={`h-3.5 w-3.5 fill-inherit`}
                                />
                                {photo.uuid === property.cover_photo_uuid
                                  ? "Cover photo"
                                  : "Set as cover photo"}
                              </button>
                              <button
                                onClick={handleDeleteImage}
                                className="flex items-center gap-1.5 text-nowrap rounded-md p-1.5 px-2.5 text-sm text-neutral-500 transition-all hover:bg-red-200"
                              >
                                <LuTrash className="h-3.5 w-3.5" />
                                Delete
                              </button>
                            </div>
                          )}
                        </div>

                        <img
                          src={`${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${photo.uuid}-large`}
                          alt=""
                          className="isolate max-h-[calc(100%-2rem)] w-auto rounded object-contain"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {photos?.length > 1 && (
              <div className="w-full">
                <HorizontalScrollDiv>
                  <div
                    ref={imageThumbScrollerRef}
                    className="flex min-w-full shrink-0 justify-center p-6 px-1"
                  >
                    {photos.map((photo, index) => (
                      <div
                        key={`thumb_${photo.uuid}`}
                        className="shrink-0 px-1"
                      >
                        <button
                          onClick={() => handleThumbnailClick(index)}
                          className={`relative overflow-hidden rounded ${
                            index === currentPhotoIndex
                              ? "opacity-100 shadow-green-600 ring-2 ring-primary-500 ring-offset-2"
                              : "opacity-80 ring-transparent ring-offset-0 md:hover:opacity-100"
                          } transition-all`}
                        >
                          <img
                            src={`${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${photo.uuid}-thumbnail`}
                            alt="House"
                            className="h-14 w-14 object-cover"
                          />
                          {loggedInUser?.id === property?.owner_id &&
                            photo.uuid === property.cover_photo_uuid && (
                              <div
                                style={{
                                  fill: "#ededed",
                                  stroke: "#ededed",
                                  filter:
                                    "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))",
                                }}
                                className="absolute bottom-1.5 left-1 z-10"
                              >
                                <LuStar className="h-3.5 w-3.5 fill-inherit stroke-inherit" />
                              </div>
                            )}
                          {loggedInUser?.id === property?.owner_id &&
                            photo?.tags?.length > 0 && (
                              <div
                                style={{
                                  fill: "#ededed",
                                  filter:
                                    "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))",
                                }}
                                className="absolute bottom-1 right-1 z-10"
                              >
                                <IoMdPricetag className="h-4 w-4 fill-inherit" />
                              </div>
                            )}
                        </button>
                      </div>
                    ))}
                  </div>
                </HorizontalScrollDiv>
              </div>
            )}
            {isDeleting && (
              <div className="absolute inset-0 z-40 flex flex-col items-center justify-center gap-2 bg-white/90 text-neutral-300">
                <Spinner label={`Deleting photo...`} />
              </div>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default PropertyProfileImageCarousel;
