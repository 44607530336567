import React from "react";
import AgentsLogo from "../../assets/agents/unlisted_agents_logo.png";
import MainLogo from "../../assets/svgs/UnlistedLogo.jsx";
import AgentsMastheadBkg from "../../assets/agents/agents_masthead_door.jpg";
import SearchExample from "../../assets/agents/search_example.png";
import BrandExample1 from "../../assets/agents/brand_example_1_small.png";
import BrandExample2 from "../../assets/agents/brand_example_2_small.png";
import BrandExample3 from "../../assets/agents/brand_example_3_small.png";
import CroppedDoor from "../../assets/agents/cropped_door.jpg";
import { MdCalendarMonth } from "react-icons/md";
import { LuInstagram, LuLinkedin } from "react-icons/lu";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { BiLogoInstagram } from "react-icons/bi";
import { AiOutlineFacebook, AiOutlineLinkedin } from "react-icons/ai";

function Agents() {
  return (
    <div className="mx-auto w-[min(calc(100%),64rem)]">
      <header className="top-0 z-40 flex flex-col items-center justify-center gap-4 bg-white/80 p-5 backdrop-blur-sm md:sticky md:flex-row md:justify-between">
        <img className="w-36" src={AgentsLogo} alt="Agents" />
        <div className="hidden md:flex">
          <ScheduleAppointmentButton />
        </div>
      </header>

      <section data-section-masthead className="grid grid-cols-1 grid-rows-1">
        <img
          className="col-start-1 col-end-2 row-start-1 row-end-2 h-full w-full object-cover"
          src={AgentsMastheadBkg}
          alt="Beautiful door"
        />
        <div className="z-10 col-start-1 col-end-2 row-start-1 row-end-2 flex h-full w-full max-w-2xl flex-col items-start justify-center gap-12 p-12 font-attention text-white">
          <h1 className="text-center text-4xl font-bold text-white drop-shadow-lg sm:text-6xl md:text-left">
            Own the off-market in your zip code
          </h1>
          <div className="mx-auto hidden md:mx-0 md:flex">
            <ScheduleAppointmentButton />
          </div>
        </div>
      </section>
      <section
        data-section-search
        className="bg-neutral-100 p-8 text-center md:text-left"
      >
        <h2 className="mx-auto max-w-xl text-balance px-6 text-center font-attention text-2xl text-neutral-700 sm:text-3xl sm:leading-[135%]">
          A property profile for every home in the country
        </h2>
        <div className="mt-12 flex flex-col gap-8 md:flex-row md:gap-4">
          <div className="flex flex-1 flex-col items-center gap-3">
            <img
              src={SearchExample}
              alt="Example of home search using Unlisted for Agents"
            />
            <span className="text-center text-xs text-neutral-500">
              Unlisted-generated property profiles of off-market homes
            </span>
          </div>
          <div className="mx-auto max-w-sm flex-1 space-y-4 p-4 pt-0 font-content text-neutral-700">
            <p>
              Unlisted for Agents takes the information in the tax records and
              makes it simple to search for off-market opportunities.
            </p>
            <p>
              We do this by creating a property profile for every single home in
              the country.
            </p>
            <p>
              We then curate lists of off-market homes that fit your clients’
              needs and may be open to selling.
            </p>
          </div>
        </div>
      </section>
      <section
        data-section-brand
        className="bg-[#D7DED9] p-12 text-center md:text-left"
      >
        <h2 className="mx-auto max-w-xl text-balance px-6 text-center font-attention text-2xl text-neutral-700 sm:text-3xl sm:leading-[135%]">
          Your name associated with every home profile in your zip code
        </h2>
        <div className="mt-8 flex max-w-full flex-col-reverse gap-2 p-2 md:mt-12 md:flex-row">
          <div className="mx-auto mt-12 flex max-w-sm flex-col gap-4 font-content text-neutral-800 md:mx-0 md:mt-6 md:basis-[40%]">
            <p>
              When you buy your zip code, your name and information are linked
              to every home profile in that zip code.
            </p>
            <p>
              Be the designated local expert who buyers and homeowners turn to.
            </p>
            <div className="mx-auto mt-4 hidden md:mx-0 md:flex">
              <ScheduleAppointmentButton />
            </div>
          </div>
          <div className="flex w-full items-center gap-3 md:basis-[60%] md:px-10">
            <div className="relative flex-1 pt-[60%]">
              <img
                className="absolute inset-0 object-contain"
                src={BrandExample1}
                alt="Example of property profile on Unlisted."
              />
            </div>
            <div className="relative flex-1 pt-[60%]">
              <img
                className="absolute inset-0 object-contain"
                src={BrandExample2}
                alt="Example of Agent Profile Page on Unlisted for Agents."
              />
            </div>
            <div className="relative flex-1 pt-[60%]">
              <img
                className="absolute inset-0 object-contain"
                src={BrandExample3}
                alt="Example of service recommendations on the Agent Profile Page."
              />
            </div>
          </div>
        </div>
      </section>
      <section
        data-section-footer
        className="bg-white p-8 text-center md:text-left"
      >
        <div className="mt-2 flex flex-col flex-col-reverse gap-2 md:flex-row md:items-center">
          <div className="flex-1 p-10 font-content text-neutral-800">
            <img
              className="mx-auto w-44 md:mx-0"
              src={AgentsLogo}
              alt="Agents"
            />
            <div className="mx-auto mt-8 max-w-sm space-y-4 md:mx-0">
              <p className="">
                Unlisted uniquely focuses on the 95% of homes that are not on
                the market.
              </p>
              <p>
                There are many more ways to leverage Unlisted for your clients.
              </p>
              <p>
                Schedule an appointment to learn more or email us directly:{" "}
                <a
                  className="text-primary-500 underline"
                  href="mailto:hello@unlistedhomes.com"
                >
                  hello@unlistedhomes.com
                </a>
              </p>
            </div>
            <div className="mt-12 hidden md:flex">
              <ScheduleAppointmentButton />
            </div>
          </div>
          <div className="relative mx-auto w-1/2 overflow-hidden rounded-tl-full rounded-tr-full pt-[70%]">
            <img
              className="absolute bottom-0 left-1/2 h-[110%] -translate-x-1/2 object-cover"
              src={CroppedDoor}
              alt="Example of home search using Unlisted for Agents"
            />
          </div>
        </div>
      </section>
      <footer className="flex items-center justify-between border-t border-t-neutral-300 p-8">
        <div className="flex flex-col">
          <MainLogo className="w-24 fill-neutral-400" />
          <span className="text-xs text-neutral-300">
            Copyright ©2025. All rights reserved.
          </span>
        </div>
        <div className="flex gap-2">
          <a
            className="rounded-full p-2 transition-all hover:bg-neutral-100"
            href="https://www.instagram.com/unlistedforagents/"
            target="_blank"
          >
            <BiLogoInstagram className="h-5 w-5 fill-neutral-400" />
          </a>
          <a
            className="rounded-full p-2 transition-all hover:bg-neutral-100"
            href="https://www.linkedin.com/company/unlistedinc/"
            target="_blank"
          >
            <AiOutlineLinkedin className="h-5 w-5 fill-neutral-400" />
          </a>
          <a
            className="rounded-full p-2 transition-all hover:bg-neutral-100"
            href="https://www.facebook.com/profile.php?id=100094543752136"
            target="_blank"
          >
            <AiOutlineFacebook className="h-5 w-5 fill-neutral-400" />
          </a>
        </div>
      </footer>
      <div className="sticky bottom-0 flex items-center justify-center bg-white/90 p-4 shadow shadow-2xl shadow-black backdrop-blur-sm md:hidden">
        <ScheduleAppointmentButton />
      </div>
    </div>
  );
}

const ScheduleAppointmentButton = () => {
  return (
    <a
      href="https://calendly.com/unlistedkatie/unlisted-for-agents-1-1-strategy-session-w-katie"
      target="_blank"
      className="inline-flex cursor-pointer items-center gap-1 whitespace-nowrap rounded-md bg-gold-500 px-4 py-2.5 font-content text-sm font-semibold text-gold-800 shadow shadow-xl shadow-gold-800/5 transition-all hover:bg-[#b5974a]"
    >
      <MdCalendarMonth className="mr-2 inline-block" />
      Schedule an appointment
    </a>
  );
};

export default Agents;
