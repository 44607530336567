import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { setEditProfilePromptLastShown } from "@src/store/features/user/slice.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArchitecturalStyleIcon from "@src/assets/svgs/ArchitecturalStyleIcon.jsx";

import edit_screens from "@src/data/edit_screens.js";
import { motion } from "framer-motion";

export default function EditProfilePrompt({ property }) {
  const dispatch = useDispatch();
  const {
    editProfilePromptLastShown,
    onboardingSlideshowComplete,
    isLoggedIn,
    homes,
  } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const timeSinceLastPrompt = editProfilePromptLastShown
    ? new Date() - new Date(JSON.parse(editProfilePromptLastShown))
    : 0;

  const minutesSinceLastPrompt = timeSinceLastPrompt
    ? Math.floor(timeSinceLastPrompt / 60000) // Divide by 60000 to get minutes
    : 0;

  const daysSinceLastPrompt = timeSinceLastPrompt
    ? Math.floor(timeSinceLastPrompt / 86400000) // Divide by 86400000 to get days
    : 0;

  const editCount = edit_screens.filter((screen) => {
    return property[screen.owner_id];
  }).length;

  const hasEditedProfile = editCount > edit_screens.length - 2;

  const percentComplete = (editCount / edit_screens.length) * 100;

  const handleEditProfile = () => {
    dismissPrompt(true);
  };

  const dismissPrompt = (complete) => {
    setOpen(false);
    dispatch(setEditProfilePromptLastShown(JSON.stringify(new Date())));
    if (complete) {
      setTimeout(() => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set("edit_screens", "true");
        setSearchParams(newSearchParams);
      }, 300);
    }
  };

  const handleMaybeLater = () => {
    dismissPrompt(false);
  };

  //need this function because search param hooks doesn't update in the useEffect after initialization
  const getCurrentSearchParams = () => {
    return new URLSearchParams(window.location.search);
  };

  useEffect(() => {
    setTimeout(() => {
      //see getCurrentSearchParams function comments above
      const currentSearchParams = getCurrentSearchParams();
      const isEditing =
        currentSearchParams.get("edit") ||
        currentSearchParams.get("edit_screens");

      const isViewingPhotos = currentSearchParams.get("viewing_photos");

      const canShowFirstTime = !editProfilePromptLastShown;
      const canShowAgain = minutesSinceLastPrompt > 59; //Show again after 1 hour
      const isHomeOwner = homes.some((home) => home.id === property.id);
      const show =
        isHomeOwner &&
        isLoggedIn &&
        !isEditing &&
        !isViewingPhotos &&
        (canShowFirstTime || canShowAgain) &&
        !hasEditedProfile;

      if (show) setOpen(show);
    }, 2000);
  }, [homes]);

  return (
    <>
      {!hasEditedProfile ? (
        <Dialog.Root open={open} onOpenChange={setOpen}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 z-50 bg-black/40 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
            <Dialog.Content
              onOpenAutoFocus={(e) => e.preventDefault()}
              className="fixed left-1/2 top-0 z-50 flex h-dvh w-full -translate-x-1/2 flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
            >
              <VisuallyHidden>
                <Dialog.Title>Please edit your profile</Dialog.Title>
                <Dialog.Description>
                  Click the button to launch the profile editor
                </Dialog.Description>
              </VisuallyHidden>
              <div className="absolute bottom-5 left-1/2 flex w-[min(calc(100%-2rem),25rem)] max-w-[90%] -translate-x-1/2 flex-col items-center justify-center overflow-hidden text-balance rounded-3xl bg-[#FAFAFA] px-8 pb-5 pt-7 shadow-2xl md:bottom-auto md:top-1/2 md:-translate-y-1/2">
                <div className="mb-2 flex flex-wrap justify-center gap-3 text-sage-700">
                  <div className="flex aspect-square h-20 w-20 shrink-0 items-center justify-center rounded-full bg-sage-200">
                    <ArchitecturalStyleIcon className="h-12 w-12 scale-90" />
                  </div>
                </div>
                <p className="mt-3 max-w-[40ch] text-balance text-center font-attention text-xl text-neutral-600">
                  {editCount < edit_screens.length - 2 ? (
                    <>Let’s make sure your profile’s up to date!</>
                  ) : (
                    <>
                      Your profile is almost complete. Just a couple more items
                      to go!
                    </>
                  )}
                </p>
                <button
                  onClick={handleEditProfile}
                  className="relative mt-7 w-full overflow-hidden rounded-full bg-[#8E9991] p-3 px-8 text-lg text-white shadow-xl shadow-primary-500/20 transition-all sm:max-w-72 md:hover:bg-[#818d84]"
                >
                  <motion.div
                    animate={{
                      x: `${percentComplete}%`,
                      transition: {
                        duration: 0.5,
                        delay: 0.5,
                        ease: "circOut",
                      },
                    }}
                    className="diagonal-stripes opacity-75"
                  >
                    <div className="bg-diagonal-stripes"></div>
                  </motion.div>
                  <span className="relative">
                    {editCount < 1 ? (
                      <>Start updating</>
                    ) : (
                      <>Continue updating</>
                    )}
                  </span>
                </button>
                <button
                  onClick={handleMaybeLater}
                  className="mt-4 w-full p-4 py-1 text-sm text-[#808080] underline md:hover:text-[#666666]"
                >
                  Maybe later
                </button>
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      ) : null}
    </>
  );
}
