import {
  getHomeownerProperties,
  updateLoggedInUserInfo,
  verifyUserOTP,
} from "@src/api/user";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchLikedProperties,
  likeProperty,
  unlikeProperty,
} from "@src/api/property";
import { setRandomAndSearchPropertyLikedStatus } from "@src/store/features/search/slice";
import { changeStatusInLikedHome } from "./slice";
import { setPropertyLikedStatus } from "@src/store/features/property/slice";
import { Preferences } from "@capacitor/preferences";
import {
  CARDS_ONBOARDING,
  EDIT_PROFILE_PROMPT_LAST_SHOWN,
  FIRST_PHOTO_PROMPT_LAST_SHOWN,
  HORIZONTAL_CARDS_SLIDESHOW,
  ONBOARDING_SLIDESHOW,
  HAS_OPENED_SEARCH_PANEL,
} from "@src/utils/constants";

export const verifyUser = createAsyncThunk(
  "user/verify_user",
  async (data, { rejectWithValue }) => {
    try {
      const response = await verifyUserOTP(data);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const getUsersLikedHomes = createAsyncThunk(
  "user/liked_homes",
  async (params, { rejectWithValue }) => {
    try {
      const response = await fetchLikedProperties(params);

      const updatedProperties = response?.properties?.map((item) => {
        return {
          ...item,
          liked: true,
        };
      });

      return {
        ...response,
        properties: updatedProperties,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const likeHome = createAsyncThunk(
  "user/like_home",
  async (propertyId, { dispatch, rejectWithValue }) => {
    try {
      const response = await likeProperty(propertyId);

      dispatch(
        setRandomAndSearchPropertyLikedStatus({ propertyId, liked: true }),
      );
      dispatch(changeStatusInLikedHome({ propertyId, liked: true }));
      dispatch(setPropertyLikedStatus({ propertyId, liked: true }));

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const unlikeHome = createAsyncThunk(
  "user/unlike_home",
  async (propertyId, { dispatch, rejectWithValue }) => {
    try {
      const response = await unlikeProperty(propertyId);

      dispatch(
        setRandomAndSearchPropertyLikedStatus({ propertyId, liked: false }),
      );
      dispatch(changeStatusInLikedHome({ propertyId, liked: false }));
      dispatch(setPropertyLikedStatus({ propertyId, liked: false }));

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateUserDetails = createAsyncThunk(
  "user/update_user_details",
  async (data, { rejectWithValue }) => {
    try {
      const response = await updateLoggedInUserInfo(data);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const loadInitialData = createAsyncThunk(
  "user/load_data_from_storage",
  async () => {
    // Remove these local storage gets when localStorage is being completely removed
    const cardsOnboardingCompleteLocalStorage = JSON.parse(
      localStorage.getItem(CARDS_ONBOARDING),
    );
    const onboardingSlideshowCompleteLocalStorage = JSON.parse(
      localStorage.getItem(ONBOARDING_SLIDESHOW),
    );
    const editProfilePromptLastShownLocalStorage = localStorage.getItem(
      EDIT_PROFILE_PROMPT_LAST_SHOWN,
    );
    const firstPhotoPromptLastShownLocalStorage = localStorage.getItem(
      FIRST_PHOTO_PROMPT_LAST_SHOWN,
    );
    const horizontalCardsSlideShowCompleteLocalStorage = localStorage.getItem(
      HORIZONTAL_CARDS_SLIDESHOW,
    );

    const { value: cardsOnboardingComplete } = await Preferences.get({
      key: CARDS_ONBOARDING,
    });
    const { value: onboardingSlideshowComplete } = await Preferences.get({
      key: ONBOARDING_SLIDESHOW,
    });
    const { value: editProfilePromptLastShown } = await Preferences.get({
      key: EDIT_PROFILE_PROMPT_LAST_SHOWN,
    });
    const { value: firstPhotoPromptLastShown } = await Preferences.get({
      key: FIRST_PHOTO_PROMPT_LAST_SHOWN,
    });
    const { value: horizontalCardsSlideShowComplete } = await Preferences.get({
      key: HORIZONTAL_CARDS_SLIDESHOW,
    });
    const { value: hasOpenedSearchPanel } = await Preferences.get({
      key: HAS_OPENED_SEARCH_PANEL,
    });

    return {
      cardsOnboardingComplete:
        cardsOnboardingCompleteLocalStorage || cardsOnboardingComplete,
      onboardingSlideshowComplete:
        onboardingSlideshowCompleteLocalStorage || onboardingSlideshowComplete,
      editProfilePromptLastShown:
        editProfilePromptLastShownLocalStorage || editProfilePromptLastShown,
      firstPhotoPromptLastShown:
        firstPhotoPromptLastShownLocalStorage || firstPhotoPromptLastShown,
      horizontalCardsSlideShowComplete:
        horizontalCardsSlideShowCompleteLocalStorage ||
        horizontalCardsSlideShowComplete,
      hasOpenedSearchPanel: hasOpenedSearchPanel
        ? JSON.parse(hasOpenedSearchPanel)
        : false,
    };
  },
);
