import property_perks from "@src/data/property_perks.js";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SelectableTag from "@src/components/SelectableTag.jsx";
import { useSelector } from "react-redux";
import { doesUserOwnProperty } from "@src/store/features/user/slice.js";

function PropertyProfilePerksDisplay({ property }) {
  const [perks, setPerks] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const isOwner = useSelector((state) =>
    doesUserOwnProperty(state, property.id),
  );

  const onDetailClick = (id) => {
    if (!isOwner) return;
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("edit", "true");
    newSearchParams.set("item", id);
    setSearchParams(newSearchParams);
  };

  useEffect(() => {
    setPerks(
      property_perks?.filter(
        (item) =>
          property.owner_perks?.includes(item.id) ||
          (property?.owner_perks === null && property?.[item.id]),
      ),
    );
  }, [property.owner_perks]);

  if (!property.owner_perks || !Object.keys(property.owner_perks).length)
    return null;

  return (
    <div onClick={() => onDetailClick("perks")}>
      <h2 className="mb-4 font-attention text-xl text-neutral-800">Perks</h2>
      <div className="flex flex-wrap gap-2">
        {perks?.map((perk, index) => (
          <SelectableTag emoji={perk.emoji} text={perk.text} key={index} />
        ))}
      </div>
    </div>
  );
}

export default PropertyProfilePerksDisplay;
