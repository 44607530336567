import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { IoCheckmarkCircle } from "react-icons/io5";
import { formatPropertyAddress } from "@src/utils/misc";
import { MdCheck, MdOutlineCheck } from "react-icons/md";
import SentIcon from "@src/assets/svgs/SentIcon.jsx";

export default function OrderSuccessDialog({ open, setOpen, property }) {
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black/40 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <Dialog.Content
            onOpenAutoFocus={(e) => e.preventDefault()}
            className="relative w-[min(calc(100%-2rem),25rem)] max-w-[90%] translate-y-0 rounded-3xl bg-[#FAFAFA] px-8 pb-6 pt-7 shadow-2xl data-[state=closed]:animate-[fade-out_200ms] data-[state=open]:animate-[fade-in_200ms]"
          >
            <VisuallyHidden>
              <Dialog.Title>Order Confirmation</Dialog.Title>
              <Dialog.Description>
                Your message has been sent successfully
              </Dialog.Description>
            </VisuallyHidden>

            <div className="flex flex-col items-center">
              <div className="mb-2 flex flex-wrap justify-center gap-3 text-sage-700">
                <div className="flex aspect-square h-28 w-28 shrink-0 items-center justify-center rounded-full bg-sage-200/70">
                  <div className="absolute -translate-x-1 translate-y-0.5">
                    <SentIcon />
                  </div>
                </div>
              </div>
              <p className="mt-2 max-w-[40ch] text-balance text-center font-attention text-xl text-neutral-600">
                Your message has been sent!
              </p>
              <p className="mt-2 text-center text-sm text-neutral-500">
                We will notify you when the owner of{" "}
                {formatPropertyAddress(property)} responds.
              </p>
              <button
                onClick={() => setOpen(false)}
                className="mt-6 rounded-lg bg-sage-600 px-8 py-2.5 font-attention text-white transition-colors hover:bg-sage-700 focus:outline-none focus:ring-2 focus:ring-sage-600 focus:ring-offset-2"
              >
                Got it
              </button>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
