import axios from "axios";
import { getAuthTokenFromStorage } from "./misc";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = import.meta.env.VITE_API_URL;

axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axiosClient.interceptors.request.use(async (request) => {
  const authToken = await getAuthTokenFromStorage();

  if (authToken) {
    request.headers.Authorization = `Token ${authToken}`;
  }
  return request;
});

const feVersion = { fe_version: "new" };

export function getRequest({ url, params = {} }) {
  return axiosClient
    .get(`${url}`, { params: { ...params, ...feVersion } })
    .then((response) => response);
}

export function postRequest({ url, payload, options = {} }) {
  let finalPayload;

  // Check if the payload is a FormData object
  if (payload instanceof FormData) {
    // Append `feVersion` manually to FormData
    finalPayload = payload;
    finalPayload.append("fe_version", "new");
  } else {
    // For non-FormData payloads, merge `payload` with `feVersion`
    finalPayload = { ...payload, ...feVersion };
  }

  // Adjust headers for FormData if needed
  const headers =
    payload instanceof FormData
      ? { "Content-Type": "multipart/form-data", ...options.headers }
      : options.headers;

  return axiosClient
    .post(`${url}`, finalPayload, { ...options, headers })
    .then((response) => response);
}

export function patchRequest({ url, payload, params = {} }) {
  return axiosClient
    .patch(`${url}`, payload, { params: { ...params, ...feVersion } })
    .then((response) => response);
}

export function deleteRequest({ url, params = {} }) {
  return axiosClient
    .delete(`${url}`, { params: { ...params, ...feVersion } })
    .then((response) => response);
}
