const content_tags = [
  "attic",
  "backyard",
  "balcony",
  "bar",
  "basement – finished",
  "basement – unfinished",
  "bathroom",
  "bedroom",
  "cellar",
  "closet",
  "craft room",
  "den",
  "dining room",
  "dressing room",
  "driveway",
  "en suite",
  "entryway",
  "family room",
  "foyer",
  "front yard",
  "game room",
  "garage",
  "garden",
  "great room",
  "greenhouse",
  "guest bathroom",
  "guest room",
  "gym",
  "hallway",
  "home theatre",
  "kids' room",
  "kitchen",
  "laundry room",
  "library",
  "living room",
  "loft",
  "media room",
  "meditation room",
  "mother-in-law suite",
  "music room",
  "nursery",
  "office",
  "pantry",
  "patio",
  "playroom",
  "pool",
  "porch",
  "powder room",
  "primary bathroom",
  "primary bedroom",
  "sauna",
  "shed",
  "spa",
  "spare bedroom",
  "storage room",
  "study",
  "sunroom",
  "terrace",
  "tree house",
  "utility room",
  "walk-in closet",
  "wardrobe",
  "wine cellar",
  "workshop",
];

export default content_tags;
