import CryptoJS from "crypto-js";
import { patchRequest, postRequest, deleteRequest } from "./axiosClient";
import { UPLOAD_PHOTO_TYPE } from "./constants";
import { toast } from "react-toastify";
import { convertToJpg } from "@src/utils/misc";

const getmd5 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (fileEvent) => {
      let binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
      const md5 = CryptoJS.MD5(binary);
      resolve(md5);
    };
    reader.onerror = () => {
      reject("file reader error");
    };
    reader.readAsArrayBuffer(file);
  });
};

const getFileChecksum = async (file) => {
  const md5 = await getmd5(file);
  const checksum = md5.toString(CryptoJS.enc.Base64);
  return checksum;
};

const createPresignedUrl = async ({
  fileName,
  fileType,
  byteSize,
  checksum,
  type,
  propertyId,
}) => {
  // Note: we don't send all of these options at the moment because the AWS Ruby SDK doesn't take in some of them
  const content_type = fileType;
  let options = {
    filename: fileName,
    byte_size: byteSize,
    checksum,
    content_type,
    metadata: {
      type: type,
    },
    directory: type,
    propertyId,
  };

  let response = postRequest({ url: "/photo_presigned_url", payload: options });
  if (response.status !== 200) return response;
  return response;
};

export const uploadPhoto = async ({ file, type, propertyId = 0 }) => {
  const checksum = await getFileChecksum(file);
  let jpgFile;
  if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
    jpgFile = await convertToJpg(file);
  } else {
    jpgFile = file;
  }
  const presignedUrlParams = await createPresignedUrl({
    fileType: jpgFile.type,
    byteSize: jpgFile.size,
    checksum,
    type,
    propertyId,
    fileName: jpgFile.name,
  });

  const s3options = {
    method: "PUT",
    body: jpgFile,
  };

  let s3response = await fetch(presignedUrlParams.data.url, s3options);

  if (s3response.status !== 200) return false;

  if (type === UPLOAD_PHOTO_TYPE.PROPERTY_COVER) {
    const response = await patchRequest({
      url: `/properties/${propertyId}/quiz/`,
      payload: {
        photo: presignedUrlParams.data.get_url,
      },
    });
    if (response.status !== 200) {
      return false;
    } else {
      return presignedUrlParams.data;
    }
  }

  if (type === UPLOAD_PHOTO_TYPE.USER_PROFILE)
    return presignedUrlParams?.data?.get_url;
};

export const uploadPhotosBulk = async ({ propertyId = 0, files }) => {
  // Prepare files with necessary conversions
  const preparedFiles = await Promise.all(
    files.map(async (fileData) => {
      if (
        fileData.file.type !== "image/jpeg" &&
        fileData.file.type !== "image/jpg"
      ) {
        try {
          const returnedJpegFile = await convertToJpg(fileData.file); // Convert non-JPG files
          fileData.file = returnedJpegFile;
        } catch (error) {
          console.error(
            `Failed to convert ${fileData.file.name} to JPG:`,
            error,
          );
          return null; // Mark conversion failures as null
        }
      }
      return fileData; // Keep JPG files as-is
    }),
  );

  // Filter out null files (failed conversions)
  const validFiles = preparedFiles.filter((fileData) => fileData.file !== null);

  if (validFiles.length === 0) {
    throw new Error("No valid files to upload.");
  }

  // Send files to the backend via `/photos` endpoint
  const results = await Promise.all(
    validFiles.map(async (fileData) => {
      const formData = new FormData();
      formData.append("file_contents", fileData.file); // File contents
      formData.append("property_id", propertyId); // Property ID
      // formData.append("content_type", fileData.file.type);
      // formData.append("original_filename", fileData.file.name);
      if (fileData.tags) {
        formData.append("tags", JSON.stringify(fileData.tags)); // Tags
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }
      //
      // console.log();

      try {
        const response = await postRequest({
          url: "api/v1/photos",
          payload: formData,
          headers: {
            "Content-Type": "multipart/form-data", // Ensure correct content type for file upload
          },
        });

        if (response.status !== 200) {
          toast.error(`Failed to upload ${fileData.file.name}`);
          return { fileName: fileData.file.name, status: "failed" };
        }

        console.log("response", response);

        return {
          fileName: fileData.file.name,
          image_uuid: response.data.uuid,
          status: "success",
        };
      } catch (error) {
        toast.error(`Error uploading ${fileData.file.name}: ${error.message}`);
        return {
          fileName: fileData.file.name,
          status: "error",
          error: error.message,
        };
      }
    }),
  );

  // Log results for debugging
  console.log("Upload results:", results);

  return results; // Return results for each file with detailed status
};

export const deleteClaimedPropertyPhoto = async (uuid) => {
  try {
    const response = await deleteRequest({
      url: "/api/v1/photos",
      params: {
        uuid: uuid,
      },
    });

    return response.status;
  } catch (error) {
    throw error.response.data;
  }
};
