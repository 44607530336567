import React, { useEffect, useRef, useState } from "react";
import PropertyProfileImage from "@src/components/propertyProfile/propertyProfileImage/PropertyProfileImage.jsx";
import { useDispatch } from "react-redux";
import { updateProperty } from "@src/store/features/property/service.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import {
  cacheImage,
  invalidateImage,
} from "@src/store/features/imageCache/slice.js";
import { UPLOAD_PHOTO_TYPE } from "@src/utils/constants";
import {
  resetTours,
  setFirstPhotoPromptLastShown,
} from "@src/store/features/user/slice.js";
import { IoMdTrash } from "react-icons/io";
import { MdCamera, MdOutlinePhotoCamera, MdPhotoCamera } from "react-icons/md";
import {
  deleteClaimedPropertyPhoto,
  uploadPhotosBulk,
} from "@src/utils/photos.js";
import { toast } from "react-toastify";

function PropertyProfileFirstPhotoInput({ property, onChange }) {
  const [isUploading, setIsUploading] = useState(false);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  // Handle the image file selection

  const handleUploadPhotos = async (event) => {
    const file = event.target.files[0];
    // console.log("file", file);

    if (file) {
      setIsUploading(true);

      try {
        const uploadedPhotoUrls = await uploadPhotosBulk({
          propertyId: property.id,
          files: [{ file: file, tags: [] }],
        });

        const photosPayload = uploadedPhotoUrls.map((response, index) => {
          return {
            uuid: response.image_uuid,
            tags: [],
          };
        });

        if (photosPayload && photosPayload.length > 0) {
          //get current property photos and add the new photos to the beginning of the array
          const newPhotos = property.photos?.concat(photosPayload);

          try {
            await dispatch(
              setPropertyPropValue({
                key: "photos",
                value: newPhotos,
              }),
            );
            // await dispatch(
            //   setPropertyPropValue({
            //     key: "cover_photo_uuid",
            //     value: photosPayload[0].uuid,
            //   }),
            // );
            // property = {
            //   ...property,
            //   photos: newPhotos,
            //   cover_photo_uuid: photosPayload[0].uuid,
            // };
            setIsUploading(false);
            onChange(photosPayload[0].uuid);
            // await dispatch(
            //   updateProperty({ propertyData: property, isDraft: true }),
            // );
          } catch (error) {
            console.error("Error updating photos:", error);
          }
        }
      } catch (error) {
        toast.error("Error uploading some files. Please try again.");
        return;
      }
    }
  };

  useEffect(() => {
    if (property.cover_photo_uuid) {
      setIsUploading(false);
    }
  }, [property.cover_photo_uuid]);

  const handleDeleteCoverPhoto = async () => {
    setIsUploading(true);
    //wait two seconds to simulate the deletion process
    await new Promise((resolve) => setTimeout(resolve, 2000));

    try {
      await deleteClaimedPropertyPhoto(property.cover_photo_uuid);
      const propertyPhotos = property.photos?.filter(
        (photo) => photo.uuid !== property.cover_photo_uuid,
      );

      await dispatch(
        cacheImage({
          propertyId: property.id,
          imageUrl: null,
        }),
      );

      await dispatch(
        setPropertyPropValue({
          key: "photos",
          value: propertyPhotos,
        }),
      );

      await dispatch(
        setPropertyPropValue({
          key: "cover_photo_uuid",
          value: null,
        }),
      );
      await dispatch(
        setPropertyPropValue({
          key: "cover_photo_url",
          value: null,
        }),
      );

      // property = {
      //   ...property,
      //   photos: propertyPhotos,
      //   cover_photo_url: null,
      //   cover_photo_uuid: null,
      // };

      await dispatch(updateProperty({ propertyData: property, isDraft: true }));
      setIsUploading(false);
    } catch (error) {
      console.error("Error updating photos:", error);
    }
  };

  // Trigger the hidden file input when the button is clicked
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="relative h-full w-full overflow-hidden">
      <PropertyProfileImage property={property} />
      {!property.cover_photo_uuid && (
        <span className="absolute bottom-1 right-2 text-xs font-bold text-white">
          Google
        </span>
      )}
      <div className="absolute inset-0 flex h-full flex-col items-center justify-center bg-black/20 p-4 text-left">
        {!isUploading && property.cover_photo_uuid && (
          <button
            onClick={handleDeleteCoverPhoto}
            className="absolute right-2 top-2 aspect-square rounded-full bg-black/70 p-2 text-red-400/90 transition-all hover:bg-black"
          >
            <IoMdTrash className="h-5 w-5" />
          </button>
        )}

        {isUploading ? (
          <>
            <div className="absolute inset-0 flex animate-pulse flex-col items-center justify-center gap-2 bg-black/80 text-neutral-300">
              <div className="border-r-red h-8 w-8 animate-spin rounded-full border-4 border-neutral-600 border-r-primary-500"></div>
              Updating image...
            </div>
          </>
        ) : (
          <button
            className="flex items-center gap-1 rounded-lg border border-neutral-300 bg-black/40 px-5 py-3 pl-3.5 text-sm font-normal text-neutral-100 transition-all hover:bg-black/60"
            onClick={handleButtonClick}
          >
            <MdOutlinePhotoCamera className="mr-2 h-4 w-4" />
            <span>
              {property.cover_photo_uuid
                ? "Change Cover Photo"
                : "Replace Street View"}
            </span>
          </button>
        )}

        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleUploadPhotos}
          style={{ display: "none" }}
        />
      </div>
    </div>
  );
}

export default PropertyProfileFirstPhotoInput;
