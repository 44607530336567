import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import MessageBubble from "@src/components/chatView/messageBubble/MessageBubble";
import { useParams, useNavigate } from "react-router-dom";
import { IoMdSend } from "react-icons/io";
import { IoArrowBackOutline } from "react-icons/io5";
import { MdLock } from "react-icons/md";
import { getMessagesData, sendMessage } from "@src/api/chat";
import { MESSAGE_REFRESH_TIME, USER_ID } from "@src/utils/constants";
import { Preferences } from "@capacitor/preferences";

function Chat() {
  const navigate = useNavigate();
  const { proposal_id } = useParams();
  const proposalDetails = useSelector(
    (state) => state.proposal.proposalDetails[proposal_id],
  );
  const [userID, setUserId] = useState(null);

  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [propertyName, setPropertyName] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const messagesEndRef = useRef(null);
  const [activeTab, setActiveTab] = useState("homes");

  const getMessages = async () => {
    try {
      const messagesResponse = await getMessagesData(proposal_id);
      const messageList = [];
      for (let message of messagesResponse.messages) {
        let userName = "You";
        if (message?.sender_id != userID) {
          if (proposalDetails?.proposal_data?.owner_id === message?.sender_id) {
            userName = "Owner";
          } else {
            userName = "Interested Party";
          }
        }
        messageList.unshift({
          text: message.text,
          sender: message.sender_id == userID,
          senderId: message.sender_id,
          userName: userName,
          time: message.created_at,
        });
      }
      setMessages(messageList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async () => {
    if (isSendingMessage) return;
    setIsSendingMessage(true);
    try {
      const response = await sendMessage({
        proposal_id: proposal_id,
        payload: {
          text: inputValue,
        },
      });
      let senderName = response.data.sender_name;
      senderName = "You";
      if (response.status == 201) {
        setMessages((prevState) => {
          const new_message = {
            text: response.data.text,
            sender: response.data.sender_id == userID,
            userName: senderName,
            time: response.data.created_at,
          };

          return [...prevState, new_message];
        });
        setInputValue("");
        const textarea = document.querySelector("textarea");
        textarea.style.height = "auto";
        textarea.rows = 1;
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error(error);
    }
    setIsSendingMessage(false);
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages?.length]);

  useEffect(() => {
    if (proposalDetails?.proposal_data?.owner_id == userID) {
      setActiveTab("buyers");
    }
    if (proposalDetails) {
      setPropertyName(
        `${proposalDetails?.proposal_data?.address_line_1 ? proposalDetails?.proposal_data?.address_line_1 + ", " : ""}` +
          (proposalDetails?.property_details?.city
            ? proposalDetails?.property_details?.city + ", "
            : "") +
          (proposalDetails?.property_details?.state
            ? proposalDetails?.property_details?.state + " "
            : "") +
          (proposalDetails?.property_details?.zip || ""),
      );
    }
  }, [proposalDetails, userID]);

  useEffect(() => {
    if (
      !userID ||
      (proposalDetails?.proposal_data?.buyer_id != userID &&
        proposalDetails?.proposal_data?.owner_id != userID)
    )
      return;
    getMessages();
    const intervalId = setInterval(() => {
      getMessages();
    }, MESSAGE_REFRESH_TIME);

    return () => clearInterval(intervalId);
    /**
     * The owner_id is added for when a user comes via a invite and claims the home
     * This then allows the polling of the messages api
     */
  }, [userID, proposal_id, proposalDetails?.proposal_data?.owner_id]);

  useLayoutEffect(() => {
    const getUserId = async () => {
      const { value } = await Preferences.get({ key: USER_ID });
      setUserId(JSON.parse(value));
    };
    getUserId();
  }, []);

  return (
    <div className="flex h-full w-full flex-col bg-gray-100">
      <div className="border-b border-gray-300 pb-[9px]">
        <div className="mb-3 mt-6 grid grid-cols-3 pt-6">
          <div className="justify-self-start">
            <IoArrowBackOutline
              onClick={() => {
                navigate(`/conversation_list?tab=${activeTab}`);
              }}
              className="ml-4 text-2xl text-grey-800"
            />
          </div>
          <div className="flex w-max flex-row items-center gap-[6px] justify-self-center">
            <MdLock className="text-grey-600" />
            <span className="font-content font-semibold">Anonymous Chat</span>
          </div>
        </div>
        <div className="line-clamp-1 overflow-hidden px-4 text-center font-content text-sm text-gray-400">
          Discussing {propertyName}
        </div>
      </div>

      <div className="h-full w-full overflow-y-auto overscroll-y-contain border-b p-4">
        {messages.map((msg, index) => (
          <MessageBubble
            key={index}
            message={msg.text}
            isSender={msg.sender}
            userName={msg.userName}
            time={msg.time}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>

      <div className="flex w-full items-center justify-center border-t border-grey-200 px-4 pb-8 pt-4">
        <div className="flex w-full items-center rounded-full border border-gold-500 bg-white shadow shadow-neutral-500/10">
          <textarea
            value={inputValue}
            className="max-h-[calc(5*1.4rem)] min-h-[14px] w-full resize-none overflow-y-auto bg-transparent px-6 py-2 text-gray-700 focus:outline-none"
            rows="1"
            onChange={(e) => {
              setInputValue(e.target.value);
              e.target.style.height = "auto";
              e.target.style.height = `${Math.min(e.target.scrollHeight, 4 * 15)}px`;
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                if (inputValue.trim()) handleSendMessage();
              }
            }}
          />
          <button
            onClick={handleSendMessage}
            disabled={!inputValue.trim()}
            className={`mx-2 text-2xl text-gold-500 transition-all duration-150 ${!inputValue.trim() ? "cursor-not-allowed opacity-40" : "opacity-80"}`}
          >
            <IoMdSend />
          </button>
        </div>
      </div>
    </div>
  );
}
export default Chat;
