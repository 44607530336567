import React, { Fragment, useEffect, useState, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as Popover from "@radix-ui/react-popover";
import { useSearchParams } from "react-router-dom";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import HouseImage from "@src/assets/house.jpg";
import HousePortraitImage from "@src/assets/house_portrait.jpg";
import content_tags from "@src/data/content_tags.js";
import { useDispatch } from "react-redux";
import {
  MdAdd,
  MdArrowBack,
  MdArrowDropDown,
  MdArrowForward,
  MdCheck,
  MdClose,
  MdFileUpload,
  MdKeyboardArrowDown,
  MdList,
  MdMenuOpen,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";
import HorizontalScrollDiv from "@src/components/horizontalScrollDiv/HorizontalScrollDiv.jsx";
import {
  deleteClaimedPropertyPhoto,
  uploadPhotosBulk,
} from "@src/utils/photos.js";
import { UPLOAD_PHOTO_TYPE } from "@src/utils/constants.js";
import { setPropertyPropValue } from "@src/store/features/property/slice.js";
import { toast } from "react-toastify";
import { updateProperty } from "@src/store/features/property/service.js";
import Spinner from "@src/components/spinner/Spinner.jsx";

function PropertyProfileImageEditor({ property, photos, setUploadPhotos }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [headerTitle, setHeaderTitle] = useState(null);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isUploadingPhotos, setIsUploadingPhotos] = useState(false);
  const [isShowingTagMenu, setIsShowingTagMenu] = useState(false);

  const MAX_TAG_LIMIT = 10;

  const handleOnTagCreated = (tags) => {
    if (photos[currentPhotoIndex].tags.length + tags.length > MAX_TAG_LIMIT)
      return;
    const newPhotos = photos.map((photo) => {
      if (photo === photos[currentPhotoIndex]) {
        return {
          ...photo,
          tags: [...photo.tags, ...tags],
        };
      }
      return photo;
    });
    setUploadPhotos(newPhotos);
  };

  const handleRemoveTag = (tag) => {
    const newPhotos = photos.map((photo) => {
      if (photo === photos[currentPhotoIndex]) {
        return {
          ...photo,
          tags: photo.tags.filter((t) => t !== tag),
        };
      }
      return photo;
    });
    setUploadPhotos(newPhotos);
  };

  const handleDeleteOnEmpty = () => {
    //remove the last tag of the current photo
    const newPhotos = photos.map((photo) => {
      if (photo === photos[currentPhotoIndex]) {
        return {
          ...photo,
          tags: photo.tags.slice(0, -1),
        };
      }
      return photo;
    });
    setUploadPhotos(newPhotos);
  };

  const closeEditor = () => {
    setOpen(false);
    const newSearchParams = new URLSearchParams(searchParams);

    setTimeout(() => {
      setUploadPhotos([]);
      newSearchParams.delete("uploading_photos");
      newSearchParams.delete("editing_photos");
      setSearchParams(newSearchParams);
    }, 300);
  };

  const compareFileNames = (file1, file2) => {
    // Remove the file extensions and compare the base names
    const base1 = file1.replace(/\.[^/.]+$/, ""); // Removes the last extension
    const base2 = file2.replace(/\.[^/.]+$/, "");

    return base1 === base2;
  };

  const handleUploadPhotos = async () => {
    setIsUploadingPhotos(true);

    console.log("photos", photos);

    try {
      const uploadedPhotoUrls = await uploadPhotosBulk({
        propertyId: property.id,
        files: photos.map((photo) => {
          return { file: photo.file, tags: photo.tags };
        }),
      });

      console.log("uploadedPhotoUrls", uploadedPhotoUrls);

      const photosPayload = uploadedPhotoUrls.map((response, index) => {
        return {
          uuid: response.image_uuid,
          tags:
            photos.find((photo) =>
              compareFileNames(photo.file.name, response.fileName),
            )?.tags || [],
        };
      });

      console.log("photosPayload", photosPayload);
      if (photosPayload && photosPayload.length > 0) {
        //get current property photos and add the new photos to the beginning of the array
        const newPhotos = property.photos?.concat(photosPayload);

        try {
          await dispatch(
            setPropertyPropValue({
              key: "photos",
              value: newPhotos,
            }),
          );
          property = { ...property, photos: newPhotos };
          setIsUploadingPhotos(false);
          await dispatch(
            updateProperty({ propertyData: property, isDraft: true }),
          );
          closeEditor();
        } catch (error) {
          console.error("Error updating photos:", error);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("Error uploading some files. Please try again.");
      return;
    }
  };

  const handleDeleteImage = async (uuid) => {
    // get the image based on the uuid
    const uuidOfPhotoToRemove = photos[currentPhotoIndex].uuid;

    try {
      await deleteClaimedPropertyPhoto(uuidOfPhotoToRemove);
      const propertyPhotos = property.photos?.filter(
        (photo) => photo.uuid !== uuidOfPhotoToRemove,
      );
      await dispatch(
        setPropertyPropValue({
          key: "photos",
          value: propertyPhotos,
        }),
      );
      property = { ...property, photos: propertyPhotos };
      console.log("property", property);

      closeEditor();
      await dispatch(updateProperty({ propertyData: property, isDraft: true }));
    } catch (error) {
      console.error("Error updating photos:", error);
    }

    // const selectedPhotoURL = allPhotos.find(
    //   (photo) => photo.uuid === urlToRemove,
    // );
    // console.log("selectedPhotoURL", selectedPhotoURL);
    // if (!selectedPhotoURL.url.startsWith("blob:")) {
    // try {
    //   await deleteClaimedPropertyPhoto(uuidOfPhotoToRemove);
    //   //onDelete(selectedPhotoURL.url);
    //   const currentPhotos = photos;
    //   const newPhotos = allPhotos.filter((photo) => photo.uuid !== urlToRemove);
    //
    //   //TODO: probably just want to set the property.photos to the newPhotos and save it
    //   setAllPhotos(newPhotos);
    //   toast.success("Photo removed successfully");
    // } catch (error) {
    //   console.error("Failed to delete photo", error);
    // }
    // } else {
    //   toast.success("Photo removed successfully");
    //   // onDelete(selectedPhotoURL.name, true);
    // }
  };

  useEffect(() => {
    console.log("reached here 1");
    console.log("photos", photos);
    if (!photos || photos.length === 0) {
      console.log("reached here 2");
      setOpen(false);
      setUploadPhotos([]);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("uploading_photos");
      newSearchParams.delete("editing_photos");
      setSearchParams(newSearchParams);
      return;
    }
    console.log("reached here 3");
    if (searchParams.get("editing_photos")) {
      console.log("reached here 4");
      setCurrentPhotoIndex(0);
      setMode("editing_photos");
      setHeaderTitle(
        photos && photos.length > 1 ? `Edit ${photos.length} Photos` : "Photo",
      );
      setOpen(true);
    } else if (searchParams.get("uploading_photos")) {
      console.log("reached here 5");
      setCurrentPhotoIndex(0);
      setMode("uploading_photos");
      setHeaderTitle(
        photos && photos.length > 1
          ? `Upload ${photos.length} Photos`
          : "Upload Photo",
      );
      setOpen(true);
    }
    console.log("reached here 6");
  }, [searchParams]);

  const handleDialogClick = (e) => {
    e.stopPropagation();
    if (isShowingTagMenu) setIsShowingTagMenu(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-50" />
        <Dialog.Content
          onOpenAutoFocus={(e) => e.preventDefault()}
          onPointerDownOutside={(e) => e.preventDefault()}
          onInteractOutside={(e) => e.preventDefault()}
          onEscapeKeyDown={(event) => {
            event.preventDefault();
          }}
          className="pointer-events-none fixed left-1/2 top-0 z-50 flex h-dvh w-dvw -translate-x-1/2 flex-col data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]"
        >
          <VisuallyHidden>
            <Dialog.Title>Please edit your profile</Dialog.Title>
            <Dialog.Description>
              Click the button to launch the profile editor
            </Dialog.Description>
          </VisuallyHidden>
          <>
            <div
              onClick={handleDialogClick}
              className="absolute left-1/2 flex h-full w-dvw -translate-x-1/2 flex-col items-center justify-center overflow-hidden text-balance bg-[#FAFAFA] md:bottom-auto md:top-1/2 md:h-auto md:max-h-[calc(100dvh-5rem)] md:w-[min(calc(100vw-2rem),48rem)] md:-translate-y-1/2 md:rounded-3xl"
            >
              <div className="flex h-full w-full flex-col justify-end overflow-hidden">
                <header className="relative z-30 flex select-none items-center justify-between border-b border-b-neutral-300 p-2 py-5 pb-4 font-content text-sm font-semibold text-neutral-500">
                  <div className="h-6 w-6" />

                  <div className="flex flex-1 items-center justify-center gap-4 text-center tracking-tighter sm:tracking-normal">
                    <div className="relative overflow-hidden whitespace-nowrap px-2">
                      <span>{headerTitle}</span>
                    </div>
                  </div>

                  <div className="h-6 w-6" />
                </header>
                <div className="flex w-full flex-1 justify-start overflow-hidden overflow-y-auto">
                  <div className="flex w-full flex-col gap-3 p-6 py-6 pb-3">
                    {photos.length > 1 && photos[currentPhotoIndex]?.name && (
                      <h2 className="mb-2 text-sm font-semibold">
                        {photos[currentPhotoIndex]?.name}
                      </h2>
                    )}
                    {mode === "uploading_photos" && (
                      <div className="flex flex-col gap-1">
                        <label className="font-content text-sm font-semibold text-neutral-700">
                          Tags
                        </label>

                        <div className="mb-1 mt-2 flex items-center justify-between text-sm text-neutral-500">
                          <span>Type a comma between multiple tags.</span>
                          {/*<div className="flex items-center gap-1.5">*/}
                          {/*  <div className="h-3.5 w-3.5 rounded-full bg-neutral-300 shadow-inner shadow-neutral-500/10"></div>*/}
                          {/*  <span>Apply to all images</span>*/}
                          {/*</div>*/}
                          {photos[currentPhotoIndex]?.tags && (
                            <span className="text-xs text-neutral-400">{`${photos[currentPhotoIndex]?.tags.length}/${MAX_TAG_LIMIT}`}</span>
                          )}
                        </div>

                        <div className="relative flex w-full flex-wrap items-start gap-2 rounded-md bg-white p-0.5 p-2 pr-4 ring-1 ring-neutral-300">
                          {/*<button className="flex w-fit items-center gap-3 rounded-lg border border-dashed border-neutral-400 bg-neutral-100 p-2 px-3 text-neutral-600">*/}
                          {/*  <MdAdd />*/}
                          {/*  Add Tags*/}
                          {/*</button>*/}

                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsShowingTagMenu(!isShowingTagMenu);
                            }}
                            className={`absolute right-2 top-2 rounded-full p-1.5 ${isShowingTagMenu ? "bg-primary-500/20 text-neutral-500" : "text-neutral-400"} transition-all hover:bg-primary-500/20 hover:text-neutral-500`}
                          >
                            <MdList className="h-6 w-6" />
                          </button>
                          <AnimatePresence>
                            {isShowingTagMenu && (
                              <motion.div
                                onClick={(e) => e.stopPropagation()}
                                initial={{ opacity: 0 }}
                                animate={{
                                  opacity: 1,
                                  transition: { duration: 0.2 },
                                }}
                                exit={{ opacity: 0 }}
                                className="absolute left-0 top-[calc(100%+0.25rem)] z-10 flex max-h-[30dvh] min-h-44 w-full flex-wrap gap-3 overflow-hidden overflow-y-auto rounded-md bg-white p-4 shadow shadow-2xl ring-1 ring-neutral-300"
                              >
                                {content_tags
                                  .filter(
                                    (tag) =>
                                      !photos[currentPhotoIndex]?.tags.includes(
                                        tag,
                                      ),
                                  )
                                  .map((tag, index) => (
                                    <button
                                      onClick={() => handleOnTagCreated([tag])}
                                      key={tag}
                                      className="flex w-fit items-center gap-1.5 overflow-hidden rounded-full bg-primary-500/10 p-2 px-4 pr-2 text-xs capitalize text-neutral-900 transition-all hover:bg-primary-500/30"
                                    >
                                      <span className="text-black/70">
                                        {tag}
                                      </span>
                                      <MdAdd className="h-3.5 w-3.5 text-neutral-400" />
                                    </button>
                                  ))}
                              </motion.div>
                            )}
                          </AnimatePresence>
                          {photos[currentPhotoIndex]?.tags.map((tag, index) => (
                            <div
                              key={index}
                              className="flex w-fit items-center gap-1.5 overflow-hidden rounded-full bg-primary-500/30 p-2 px-4 pr-2 capitalize text-neutral-800"
                            >
                              <span className="text-sm text-black/70">
                                {tag}
                              </span>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveTag(tag);
                                }}
                                className="flex h-5 w-5 items-center justify-center rounded-full transition-colors md:hover:bg-white/40"
                              >
                                <MdClose className="h-3.5 w-3.5 text-neutral-800" />
                              </button>
                            </div>
                          ))}

                          {photos[currentPhotoIndex]?.tags.length <
                            MAX_TAG_LIMIT && (
                            <Autocomplete
                              className="flex-1 basis-44 p-1.5 px-2 focus:border-none focus:outline-none"
                              onTagCreated={handleOnTagCreated}
                              onDeleteEmpty={handleDeleteOnEmpty}
                              tags={photos[currentPhotoIndex]?.tags}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      className={`flex w-full flex-1 flex-col gap-2 ${photos?.length > 1 ? "pb-0" : "pb-4"}`}
                    >
                      {mode === "uploading_photos" && (
                        <label className="font-content text-sm font-semibold text-neutral-700">
                          Preview
                        </label>
                      )}
                      {photos.map(
                        (photo, index) =>
                          index === currentPhotoIndex && (
                            <div
                              key={photo.url}
                              className="relative max-h-96 min-h-40 w-full flex-1 overflow-hidden rounded-md bg-neutral-800"
                              style={{
                                backgroundImage: `url(${photo.url})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                            >
                              {/*<div className="absolute inset-0 isolate z-0 overflow-hidden rounded-md"></div>*/}
                              <img
                                src={photo.url}
                                alt="House"
                                className="isolate h-full w-full bg-black/70 object-contain backdrop-blur"
                              />
                            </div>
                          ),
                      )}
                    </div>
                    {photos?.length > 1 && (
                      <HorizontalScrollDiv className="sticky bottom-0 bg-[#FAFAFA]">
                        <div className="flex shrink-0 p-3 px-1">
                          {photos.map((photo, index) => (
                            <div
                              key={`thumb_${photo.url}`}
                              className="shrink-0 px-1"
                            >
                              <button
                                onClick={() => setCurrentPhotoIndex(index)}
                                className={`overflow-hidden rounded ${index === currentPhotoIndex ? "opacity-100 shadow-green-600 ring-2 ring-primary-500 ring-offset-2" : "opacity-40 ring-transparent ring-offset-0 md:hover:opacity-100"} transition-all`}
                              >
                                <img
                                  src={photo.url}
                                  alt="House"
                                  className="h-14 w-14 object-cover"
                                />
                              </button>
                            </div>
                          ))}
                        </div>
                      </HorizontalScrollDiv>
                    )}
                  </div>
                </div>

                <footer
                  className={`relative flex w-full shrink-0 items-center justify-between overflow-hidden border-t border-t-neutral-300 px-6 py-5`}
                >
                  {mode === "editing_photos" && (
                    <button
                      className="mr-auto flex cursor-pointer items-center gap-2 rounded-md bg-red-400 p-2 px-5 text-neutral-500 text-white"
                      onClick={handleDeleteImage}
                    >
                      <span>{`Delete Photo${photos.length > 1 ? " " + currentPhotoIndex + 1 : ""}`}</span>
                    </button>
                  )}
                  <div
                    className={`ml-auto flex w-fit flex-row items-center gap-3`}
                  >
                    <button
                      onClick={closeEditor}
                      className="mx-auto flex w-full justify-center gap-2 rounded-md bg-neutral-200 p-2 px-5 text-neutral-500"
                    >
                      {mode === "uploading_photos" ? "Cancel" : "Close"}
                    </button>
                    {mode === "uploading_photos" && (
                      <button
                        onClick={handleUploadPhotos}
                        className="mx-auto flex w-full items-center justify-center gap-3 whitespace-nowrap rounded-md bg-primary-500 p-2 px-5 text-white"
                      >
                        {/*<div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-white transition duration-100" />*/}
                        <MdFileUpload className="-ml-1 h-4 w-4" />
                        Upload Photos
                      </button>
                    )}
                  </div>
                </footer>
              </div>
              {isUploadingPhotos && (
                <div className="absolute inset-0 flex flex-col items-center justify-center gap-2 bg-white/90 text-neutral-300">
                  <Spinner
                    label={`Uploading ${photos.length} ${photos.length > 1 ? "photos" : "photo"}...`}
                  />
                </div>
              )}
            </div>
          </>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default PropertyProfileImageEditor;

const Autocomplete = ({ className, onTagCreated, tags, onDeleteEmpty }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  // Handle input change
  const handleInputChange = (e) => {
    //see if the last character is a comma

    const value = e.target.value;
    setInputValue(value);
    setHighlightedIndex(-1); // Reset the highlighted index on new input

    if (value) {
      const filtered = content_tags.filter((suggestion) => {
        if (value.includes(",") && value.split(",").length > 1) {
          const tags = value.split(",").map((tag) => tag.trim());
          return suggestion
            .toLowerCase()
            .startsWith(tags[tags.length - 1].toLowerCase());
        } else {
          return suggestion.toLowerCase().startsWith(value.toLowerCase());
        }
      });

      const availableTags = filtered.filter((tag) => !tags.includes(tag));

      if (availableTags.length > 0) setHighlightedIndex(0);
      setFilteredSuggestions(availableTags.slice(0, 5));
      setIsOpen(availableTags.length > 0);
    } else {
      setFilteredSuggestions([]);
      setIsOpen(false);
    }

    if (e.target.value.slice(-1) === "," || e.target.value.slice(-1) === " ") {
      setFilteredSuggestions([]);
      setHighlightedIndex(-1);
      setIsOpen(false);
    }
  };

  // Handle selection
  const handleSelect = (values) => {
    //setInputValue(value);

    if (values.length === 0) return;

    //see if tags array includes any of the items in given values array
    // and if it does, remove that item from the values array
    const newValues = values.filter((value) => !tags.includes(value));

    onTagCreated(newValues);
    setInputValue("");
    setIsOpen(false);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && inputValue === "") {
      onDeleteEmpty();
      return;
    }
    if (e.key === "ArrowDown") {
      e.preventDefault();
      // Move down the list
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0,
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      // Move up the list
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1,
      );
    } else if (
      (e.key === "Enter" || e.key === "Tab") &&
      highlightedIndex >= 0
    ) {
      e.preventDefault();
      // Select the highlighted suggestion
      const inputTags = inputValue
        .split(",")
        .map((tag) => {
          //make the first letter a capital letter
          return tag.toLowerCase().trim();
        })
        .filter((tag) => {
          const inAvailableTags = content_tags.includes(tag.toLowerCase());
          console.log(tag);

          return tag.length > 0 && inAvailableTags;
        });

      console.log("inputTags", inputTags);

      const indexForLastEntry = inputTags.length ? inputTags.length - 1 : 0;
      inputTags[indexForLastEntry] = filteredSuggestions[highlightedIndex];

      handleSelect(inputTags);
    }
    //COMMENTED TO PREVENT ADDING CUSTOM TAGS
    // else if (
    //   (e.key === "Enter" || e.key === "Tab") &&
    //   highlightedIndex === -1
    // ) {
    //   e.preventDefault();
    //   //need to first separate the inputValue by comma
    //   //then add each tag to the tags array
    //   const tags = inputValue
    //     .split(",")
    //     .map((tag) => {
    //       //make the first letter a capital letter
    //       tag = tag.toLowerCase().trim();
    //       return tag.charAt(0).toUpperCase() + tag.slice(1);
    //     })
    //     .filter((tag) => tag.length > 0);
    //   handleSelect(["hello", "world"]);
    // }
    else if (e.key === "Escape") {
      // Close the dropdown
      setIsOpen(false);
    }
  };

  useEffect(() => {
    console.log("tags changed");
    inputRef.current.focus();
  }, [tags]);

  return (
    <Popover.Root open={isOpen}>
      <Popover.Trigger asChild>
        {/*<input placeholder="Enter tags..." className="" />*/}
        <input
          type="text"
          ref={inputRef}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className={className}
          onBlur={() => setInputValue("")}
          placeholder="Enter tags..."
        />
        {/*<button className="absolute right-3 top-1/2 -translate-y-1/2">*/}
        {/*  <MdKeyboardArrowDown className="h-6 w-6 text-neutral-500" />*/}
        {/*</button>*/}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="z-50 rounded border bg-white shadow-lg"
          align="start" // Align the dropdown to the start of the input
          sideOffset={5} // Offset to provide some space between the input and the dropdown
          style={{
            minWidth: "100%",
            pointerEvents: "auto",
          }} // Make sure the dropdown is at least as wide as the input
          onOpenAutoFocus={(e) => e.preventDefault()}
          onEscapeKeyDown={() => setIsOpen(false)}
        >
          <div className="p-2">
            {filteredSuggestions.map((suggestion, index) => (
              <div
                key={index}
                onClick={() => handleSelect([suggestion])}
                className={`cursor-pointer rounded p-2 text-sm capitalize text-neutral-700 ${
                  highlightedIndex === index ? "bg-gray-100" : ""
                }`}
              >
                {suggestion}
              </div>
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
