import React, { useEffect, useState, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { cacheImage } from "../../../store/features/imageCache/slice.js";
import { useSelector, useDispatch } from "react-redux";
import { useApiIsLoaded } from "@vis.gl/react-google-maps";

function PropertyProfileImage({ property, onFallback, showAerialView = true }) {
  const dispatch = useDispatch();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "2000px",
  });

  const cachedImageUrl = useSelector((state) => state.imageCache[property.id]);
  const [imageUrl, setImageUrl] = useState(cachedImageUrl || null);
  const [isFetching, setIsFetching] = useState(false);
  const isLoaded = useApiIsLoaded();

  const preloadImage = (url) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      setImageUrl(url);
      dispatch(cacheImage({ propertyId: property.id, imageUrl: url }));
      setIsFetching(false);
    };
  };

  const fetchImageWithContentLength = async (url) => {
    try {
      const response = await fetch(url);
      const contentLength = response.headers.get("content-length");
      return contentLength && parseInt(contentLength, 10) >= 10000;
    } catch (error) {
      console.error("Error fetching image:", error);
      return false;
    }
  };

  const computeHeading = (originLatLng, propertyLatLng) => {
    return window.google.maps.geometry.spherical.computeHeading(
      originLatLng,
      propertyLatLng,
    );
  };

  const fetchDirectionsAndStreetView = useCallback(async () => {
    if (!isLoaded || !window.google) {
      console.warn("Google Maps API is not loaded yet.");
      return;
    }

    const directionsService = new window.google.maps.DirectionsService();
    const fullAddress = `${property.address_line_1} ${property.city} ${property.state} ${property.zip}`;

    const request = {
      origin: fullAddress,
      destination: fullAddress,
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    return new Promise((resolve, reject) => {
      directionsService.route(request, (result, status) => {
        if (
          status === window.google.maps.DirectionsStatus.OK &&
          result.routes.length > 0
        ) {
          const steps = result.routes[0].legs[0].steps;
          const streetLocation = steps[steps.length - 1].start_location;
          const propertyLocation = new window.google.maps.LatLng(
            property.latitude,
            property.longitude,
          );

          const heading = computeHeading(streetLocation, propertyLocation);

          resolve({ streetLocation, heading });
        } else {
          console.error("Failed to fetch directions:", status);
          reject("Failed to fetch directions");
        }
      });
    });
  }, [isLoaded, property]);

  const fetchStreetViewUrl = useCallback(async () => {
    if (isFetching) return;
    setIsFetching(true);

    if (property.cover_photo_uuid) {
      const url = `${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${property.cover_photo_uuid}-large`;
      preloadImage(url);
      return;
    }

    if (cachedImageUrl) {
      preloadImage(cachedImageUrl);
      return;
    }

    try {
      const { streetLocation, heading } = await fetchDirectionsAndStreetView();
      const streetViewUrl = `https://maps.googleapis.com/maps/api/streetview?size=640x640&location=${streetLocation.lat()},${streetLocation.lng()}&fov=80&heading=${heading}&pitch=0&scale=2&source=outdoor&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;

      const isValidImage = await fetchImageWithContentLength(streetViewUrl);
      if (isValidImage) {
        preloadImage(streetViewUrl);
      } else {
        console.warn("Invalid Street View image, switching to aerial view");
        displayAerialView();
      }
    } catch (error) {
      console.error("Error fetching Street View:", error);
      displayAerialView();
    }
  }, [
    isFetching,
    property.cover_photo_uuid,
    cachedImageUrl,
    fetchDirectionsAndStreetView,
  ]);

  const displayAerialView = () => {
    const aerialViewUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${property.latitude},${property.longitude}&zoom=20&size=640x640&maptype=satellite&key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;

    if (showAerialView) {
      preloadImage(aerialViewUrl);
    } else if (onFallback) {
      onFallback();
    }
  };

  useEffect(() => {
    if (isLoaded && inView && !cachedImageUrl && !isFetching) {
      fetchStreetViewUrl();
    }
  }, [isLoaded, inView, cachedImageUrl, isFetching, fetchStreetViewUrl]);

  useEffect(() => {
    console.log(
      "responded to change in property.cover_photo_uuid",
      property.cover_photo_uuid,
    );
    if (property.cover_photo_uuid) {
      const url = `${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${property.cover_photo_uuid}-large`;
      preloadImage(url);
    } else {
      // Clear image and trigger fallback logic
      // preloadImage(null);
      // fetchStreetViewUrl();
    }
  }, [property.cover_photo_uuid]);

  return (
    <div ref={ref} className="h-full w-full">
      {inView && imageUrl ? (
        <img
          className="h-full w-full object-cover"
          src={imageUrl}
          alt={`Street view of ${property.address_line_1}`}
        />
      ) : (
        <div className="flex h-full w-full items-center justify-center bg-gray-200" />
      )}
    </div>
  );
}

export default PropertyProfileImage;
