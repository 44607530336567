import React, { Fragment, useEffect, useState, useRef, useMemo } from "react";
import {
  MdArrowBack,
  MdChevronRight,
  MdImage,
  MdOutlineAddAPhoto,
  MdOutlineChecklist,
  MdOutlineImage,
  MdTag,
  MdTagFaces,
} from "react-icons/md";
import checklist_options from "@src/data/checklist_options.js";
import { useSearchParams } from "react-router-dom";
import AddAPhotoIcon from "@src/assets/svgs/AddAPhotoIcon.jsx";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import * as Dialog from "@radix-ui/react-dialog";
import PropertyProfileImageEditor from "@src/components/propertyProfile/propertyProfileEditor/propertyProfileEditorFormItems/propertyProfileImageEditor/PropertyProfileImageEditor.jsx";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import { IMAGE_SIZE_LIMIT } from "@src/utils/constants.js";
import { toast } from "react-toastify";
import HorizontalScrollDiv from "@src/components/horizontalScrollDiv/HorizontalScrollDiv.jsx";
import { IoMdPricetag } from "react-icons/io";
import PropertyProfileImageCarousel from "@src/components/propertyProfile/propertyProfileImageCarousel/PropertyProfileImageCarousel.jsx";
import { LuStar } from "react-icons/lu";

function PropertyProfileImageManager({ property }) {
  const [selectedTab, setSelectedTab] = useState("all_photos");
  const [tabPosition, setTabPosition] = useState("0");
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedBucket, setSelectedBucket] = useState(null);
  const [currentUploadTag, setCurrentUploadTag] = useState(null);
  const uploadPhotoRef = useRef(null);
  const [uploadPhotos, setUploadPhotos] = useState([]);
  const screenControls = useAnimation();
  const tabControls = useAnimation();
  const [animateToItem, setAnimateToItem] = useState(false);
  const showCheckListInUI = false;

  // const allPhotos = useMemo(() => {
  //   console.log("photos", property?.photos);
  //   console.log("property", property);
  //   let propertyPhotos = [];
  //   if (property?.photos?.length) {
  //     propertyPhotos = property.photos.map((photo) => {
  //       return {
  //         url: `${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${photo.uuid}`,
  //       };
  //     });
  //   }
  //
  //   // let photos = [];
  //   // if (property?.photos?.length) {
  //   //   photos = [
  //   //     ...property.photos.map((url) => {
  //   //       return { url };
  //   //     }),
  //   //   ];
  //   // }
  //
  //   // if (uploadingPhotos.length) {
  //   //   photos = [
  //   //     ...photos,
  //   //     ...uploadingPhotos.map((file) => ({
  //   //       url: URL.createObjectURL(file),
  //   //       name: file.name,
  //   //       uploading: isUploadingPhotos,
  //   //     })),
  //   //   ];
  //   // }
  //
  //   return propertyPhotos;
  // }, [property?.photos]);

  // console.log("allPhotos", allPhotos);

  const [allPhotos, setAllPhotos] = useState(property.photos || []);

  const checklists = checklist_options.map((checklistItem) => {
    return {
      id: checklistItem.id,
      name: checklistItem.name,
      buckets: checklistItem.buckets.map((bucket) => {
        return {
          id: bucket.id,
          name: bucket.name,
          tags: bucket.tags,
          photos: property.photos?.filter((photo) => {
            if (photo.tags) {
              return photo.tags.some((tag) => bucket.tags.includes(tag));
            }
          }),
        };
      }),
    };
  });

  const handleTabChange = (tabIndex) => {
    const newSearchParams = new URLSearchParams(searchParams);
    setAnimateToItem(true);
    newSearchParams.set("photo_tab", tabIndex);
    setSearchParams(newSearchParams);
  };

  const handleBucketSelected = (bucketId) => {
    setSelectedBucket(bucketId);
  };

  const handleImageClicked = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("editing_photo", true);
    setSearchParams(newSearchParams);
  };

  const handleAddNewImage = (bucketId) => {
    if (bucketId) {
      const topLevelTag = checklist_options
        .flatMap((checklist) => checklist.buckets) // Flatten all buckets into a single array
        .find((bucket) => bucket.id === bucketId)?.tags?.[0];

      setCurrentUploadTag(topLevelTag);
    }

    uploadPhotoRef.current.click();

    // const newSearchParams = new URLSearchParams(searchParams);
    // newSearchParams.set("editing_photo", true);
    // setSearchParams(newSearchParams);
  };

  const handleFileUpload = (e) => {
    console.log("allPhotos", allPhotos);
    const currentPhotos = new Set(
      allPhotos.map((urlObj) => urlObj.name || urlObj.uuid),
    );

    // console.log("currentPhotos", currentPhotos);

    const allFiles = Array.from(e.target.files);

    console.log("allFiles", allFiles);

    let filteredLarge = "";
    let multipleLarge = false;
    let filteredDuplicate = "";
    let multipleDuplicate = false;
    /**
     * Filter out all images which are either
     * greater than the image limit (10MB)
     * or a duplicate
     */
    const files = allFiles?.filter((file) => {
      const isSmallerThanLimit = file?.size / (1000 * 1000) < IMAGE_SIZE_LIMIT;
      if (!isSmallerThanLimit) {
        if (filteredLarge?.length) multipleLarge = true;
        filteredLarge += `${filteredLarge?.length ? ", " : ""}${file?.name}`;
      }

      const isDuplicate = currentPhotos.has(file.name);
      if (isDuplicate) {
        if (filteredDuplicate?.length) multipleDuplicate = true;
        filteredDuplicate += `${filteredDuplicate?.length ? ", " : ""}${file?.name}`;
      }

      return isSmallerThanLimit && !isDuplicate;
    });

    console.log("files", files);
    //
    // if (filteredLarge?.length) {
    //   toast.error(
    //       `Could not upload ${filteredLarge} as ${multipleLarge ? "they are" : "it is"} beyond the ${IMAGE_SIZE_LIMIT}MB limit`,
    //   );
    // }
    //
    // if (filteredDuplicate?.length) {
    //   toast.error(
    //       `The following file${multipleDuplicate ? "s" : ""} already exist${!multipleDuplicate ? "s" : ""}: ${filteredDuplicate}`,
    //   );
    // }
    //
    const totalFilesCount = allPhotos.length + files.length;
    if (totalFilesCount > 20) {
      toast.error("You can upload a maximum of 20 images.");
      return;
    }
    const photoBlobURLs = files?.map((file) => ({
      file: file,
      url: URL.createObjectURL(file),
      name: file.name,
      // photo: currentUploadTag, //TODO: maybe remove this
      tags: currentUploadTag ? [currentUploadTag] : [],
    }));

    console.log("photoBlobURLs", photoBlobURLs);

    if (files?.length) {
      // setAllPhotos((prevAllPhotos) => [...prevAllPhotos, ...photoBlobURLs]);
      setUploadPhotos([...photoBlobURLs]);
      //onChange(files);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("uploading_photos", true);
      setSearchParams(newSearchParams);
    }
  };

  const handleEditImages = (uuids) => {
    const photosToEdit = allPhotos
      .filter((photo) => uuids.includes(photo.uuid))
      .map((photo, index) => {
        return {
          url: `${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${photo.uuid}`,
          name: `Photo ${index + 1}`,
          uuid: photo.uuid,
          tags: photo.tags,
        };
      });

    if (photosToEdit?.length) {
      setUploadPhotos([...photosToEdit]);
      //onChange(files);
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("editing_photos", true);
      setSearchParams(newSearchParams);
    }
  };

  const handleViewImage = (uuid) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("viewing_photos", true);
    newSearchParams.set("photo_id", uuid);
    setSearchParams(newSearchParams);
  };

  const getTagLabelFromId = (tagId) => {
    const tag = checklist_options
      .flatMap((checklist) => checklist.buckets)
      .find((tag) => tag.id === tagId);
    return tag.name;
  };

  useEffect(() => {
    const currentTab = searchParams.get("photo_tab");
    setSelectedTab(currentTab);
    setTabPosition(
      currentTab
        ? currentTab === "all_photos"
          ? "0"
          : !showCheckListInUI
            ? "0"
            : "full"
        : "0",
    );

    setTabIndex(
      currentTab
        ? currentTab === "all_photos"
          ? 0
          : !showCheckListInUI
            ? 0
            : 1
        : 0,
    );
  }, [searchParams]);

  useEffect(() => {
    const screenPosition = `${(tabIndex / 2) * -100}%`;
    if (animateToItem) {
      tabControls.start({
        x: tabIndex === 0 ? "0%" : "100%",
      });

      screenControls.start({
        x: screenPosition,
      });
    } else {
      tabControls.set({
        x: tabIndex === 0 ? "0%" : "100%",
      });

      screenControls.set({
        x: screenPosition,
      });
    }
  }, [tabIndex]);

  useEffect(() => {
    setAllPhotos(property.photos);
  }, [property.photos]);

  return (
    <div className="flex h-full flex-col">
      <VisuallyHidden>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          multiple
          ref={uploadPhotoRef}
          className="hidden"
          onChange={handleFileUpload}
        />
      </VisuallyHidden>
      <AnimatePresence>
        {!selectedBucket && (
          <motion.header
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="px-8 pt-0"
          >
            <nav>
              {showCheckListInUI && (
                <ul className="relative flex w-full items-end border-b border-b-neutral-200 font-attention">
                  <motion.div
                    initial={{ x: "0%" }}
                    animate={tabControls}
                    transition={{ ease: "circInOut" }}
                    className={`absolute bottom-0 left-0 h-1 w-1/2 rounded-tl-xl rounded-tr-xl bg-primary-500`}
                  ></motion.div>
                  <button
                    onClick={() => handleTabChange("all_photos")}
                    className={`${selectedTab === "all_photos" ? "pointer-events-none opacity-100" : "opacity-50"} group flex flex-1 items-center justify-center gap-1.5 p-3 py-4`}
                  >
                    <MdOutlineImage className="opacity-50" /> All photos{" "}
                    <span className="font-content opacity-50">{`(${allPhotos.length})`}</span>
                  </button>
                  <button
                    onClick={() => handleTabChange("photo_checklist")}
                    className={`${selectedTab === "photo_checklist" ? "pointer-events-none opacity-100" : "opacity-50 md:hover:opacity-100"} group flex flex-1 items-center justify-center gap-1.5 p-3 py-4`}
                  >
                    <MdOutlineChecklist
                      className={`opacity-50 group-[selected]:opacity-100`}
                    />{" "}
                    Checklist
                  </button>
                </ul>
              )}
            </nav>
          </motion.header>
        )}
      </AnimatePresence>

      <div className="flex flex-1 overflow-hidden">
        <motion.div
          initial={{
            x: `${(tabIndex / 2) * -100}%`,
          }}
          animate={screenControls}
          transition={{ ease: "circInOut" }}
          className="grid h-full w-fit grid-flow-col"
        >
          <div
            data-tab="all_photos"
            className="relative h-full w-dvw shrink-0 flex-grow basis-full overflow-y-auto px-8 py-6 md:w-[min(calc(100vw-2rem),42rem)]"
          >
            {allPhotos.length > 0 && (
              <button
                onClick={handleAddNewImage}
                className={`fixed bottom-8 ${showCheckListInUI ? "left-1/4" : "left-1/2"} z-10 flex -translate-x-1/2 cursor-pointer gap-3 rounded-full bg-primary-500 px-5 py-3 pl-4 pr-6 text-white shadow-xl shadow-sage-700/10 transition-all hover:bg-sage-700/80`}
              >
                <AddAPhotoIcon className="max-w-12 translate-x-1" />
                <span>Add photos</span>
              </button>
            )}
            <AnimatePresence>
              {!selectedBucket && tabIndex === 0 && (
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="h-full w-full"
                >
                  {allPhotos.length > 0 ? (
                    <>
                      <p className="pb-5 text-center text-sm text-neutral-500">{`${allPhotos.length} ${allPhotos.length > 1 ? "photos" : "photo"} `}</p>
                      <div className="grid auto-rows-auto grid-cols-[repeat(auto-fill,_minmax(6rem,_1fr))] gap-1 pb-28">
                        {/*<button*/}
                        {/*  onClick={handleAddNewImage}*/}
                        {/*  className="r flex aspect-square flex-col items-center justify-center gap-1 overflow-hidden bg-neutral-200"*/}
                        {/*>*/}
                        {/*  <AddAPhotoIcon className="h-auto w-1/2 max-w-12 translate-x-1 fill-neutral-500" />*/}
                        {/*  /!*<span className="text-xs text-neutral-500">Add photo</span>*!/*/}
                        {/*</button>*/}
                        {allPhotos.map((photo, index) => (
                          <button
                            onClick={() => handleViewImage(photo.uuid)}
                            key={`display_${photo.uuid}_${index}`}
                            className="relative flex aspect-square cursor-pointer flex-col items-center justify-center gap-1 overflow-hidden rounded-sm bg-white transition-all hover:brightness-90"
                          >
                            <img
                              className="h-full w-full object-cover"
                              src={`${import.meta.env.VITE_CLOUDFRONT_URL}/photos/${photo.uuid}-thumbnail`}
                              alt=""
                            />
                            {photo.uuid === property.cover_photo_uuid && (
                              <div
                                style={{
                                  fill: "#ededed",
                                  stroke: "#ededed",
                                  filter:
                                    "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))",
                                }}
                                className="absolute bottom-1.5 left-1 z-10"
                              >
                                <LuStar className="h-5 w-5 fill-inherit stroke-inherit" />
                              </div>
                            )}
                            {photo.tags?.length > 0 && (
                              <div
                                style={{
                                  fill: "#ededed",
                                  filter:
                                    "drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25))",
                                }}
                                className="absolute bottom-1 right-1 z-10"
                              >
                                <IoMdPricetag className="h-6 w-6 fill-inherit" />
                              </div>
                            )}
                          </button>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
                      <h2 className="text-center font-attention text-2xl text-neutral-900 md:text-2xl">
                        You haven&apos;t added any photos yet.
                      </h2>
                      <p className="mx-auto mt-3 max-w-[40ch] text-pretty text-center text-neutral-600">
                        Include some images that capture your home’s unique
                        charm.
                      </p>
                      <button
                        onClick={handleAddNewImage}
                        className="z-10 mt-10 flex cursor-pointer gap-3 rounded-full bg-primary-500 px-5 py-3 pl-4 pr-6 text-white shadow-xl shadow-sage-700/10 transition-all hover:bg-sage-700/80"
                      >
                        <AddAPhotoIcon className="max-w-12 translate-x-1" />
                        <span>Add some photos</span>
                      </button>
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {showCheckListInUI && (
            <div
              data-tab="checklist"
              className="h-full w-dvw shrink-0 flex-grow basis-full overflow-y-auto px-0 pb-6 md:w-[min(calc(100vw-2rem),42rem)]"
            >
              <AnimatePresence>
                {!selectedBucket && tabIndex === 1 && (
                  <motion.div
                    initial={{ opacity: 1 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className=""
                  >
                    {checklists.map((checklist) => {
                      return (
                        <div
                          className="first:mt-2 even:border-y even:border-y-neutral-200 even:bg-sage-200/50"
                          key={checklist.id}
                        >
                          <h2 className="sticky top-0 px-8 py-4 pt-3 font-content text-sm font-semibold text-neutral-500">
                            {checklist.name}
                          </h2>
                          {/*<div className="grid auto-rows-auto grid-cols-[repeat(auto-fill,_minmax(5rem,_1fr))] gap-6 gap-y-8 px-8">*/}
                          <HorizontalScrollDiv className="pr-4">
                            <div className="flex gap-4 p-1 px-8 pb-6">
                              {checklist.buckets.map((bucket) => {
                                return (
                                  <Fragment key={bucket.id}>
                                    {bucket.photos.length > 0 ? (
                                      <div
                                        key={bucket.id}
                                        className="flex max-w-28 shrink-0 basis-full flex-col gap-2"
                                      >
                                        <button
                                          onClick={() =>
                                            handleBucketSelected(bucket.id)
                                          }
                                          className="flex aspect-square flex-col items-center justify-center gap-1 overflow-hidden rounded-lg bg-white shadow-md shadow-neutral-500/10 ring-1 ring-neutral-300"
                                        >
                                          <span className="text-xs text-neutral-500">
                                            photos go here
                                          </span>
                                        </button>
                                        <span className="text-center text-xs text-neutral-600">
                                          {bucket.name}
                                        </span>
                                      </div>
                                    ) : (
                                      <div
                                        key={bucket.id}
                                        className="flex max-w-28 shrink-0 basis-full flex-col gap-2"
                                      >
                                        <button
                                          onClick={() =>
                                            handleAddNewImage(bucket.id)
                                          }
                                          className="flex aspect-square flex-col items-center justify-center gap-1 overflow-hidden rounded-lg bg-white shadow-md shadow-neutral-500/10 ring-1 ring-neutral-300"
                                        >
                                          <AddAPhotoIcon className="h-auto w-1/2 max-w-12 translate-x-0.5 fill-neutral-400" />
                                          {/*<span className="text-xs text-neutral-500">Add photo</span>*/}
                                        </button>
                                        <span className="text-center text-xs text-neutral-600">
                                          {bucket.name}
                                        </span>
                                      </div>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </div>
                          </HorizontalScrollDiv>
                        </div>
                      );
                    })}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </motion.div>
        <AnimatePresence>
          {selectedBucket && (
            <motion.div
              initial={{ translateX: "100%" }}
              animate={{ translateX: "0%" }}
              exit={{ translateX: "100%" }}
              transition={{ ease: "circInOut" }}
              className="absolute left-0 top-0 h-full w-full bg-white"
            >
              <header className="px-8 pt-4">
                <nav>
                  <ul className="relative flex w-full items-center justify-start font-attention">
                    <button
                      onClick={() => setSelectedBucket(null)}
                      className={`group my-2 -ml-2 flex items-center justify-center gap-1.5 rounded-full px-3 py-2 pr-5 transition-all md:hover:bg-primary-500/20`}
                    >
                      {/*<MdOutlineChecklist*/}
                      {/*  className={`opacity-50 group-[selected]:opacity-100`}*/}
                      {/*/>{" "}*/}
                      <MdArrowBack className="h-4 w-4" />
                      Checklist
                    </button>
                    <MdChevronRight className="opacity-50" />
                    <span
                      className={`group my-2 flex items-center justify-center gap-1.5 p-3 py-2`}
                    >
                      {getTagLabelFromId(selectedBucket)}
                    </span>
                  </ul>
                </nav>
              </header>
              <div className="grid auto-rows-auto grid-cols-[repeat(auto-fill,_minmax(7rem,_1fr))] px-8 py-6">
                <button
                  onClick={handleImageClicked}
                  className="flex aspect-square flex-col items-center justify-center gap-1 overflow-hidden rounded-lg bg-white shadow-md shadow-neutral-500/10 ring-1 ring-neutral-300"
                >
                  <AddAPhotoIcon className="h-12 w-12 fill-neutral-400" />
                  {/*<span className="text-xs text-neutral-500">Add photo</span>*/}
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <PropertyProfileImageEditor
          property={property}
          photos={uploadPhotos}
          currentUploadTag={currentUploadTag}
          setUploadPhotos={setUploadPhotos}
        />
      </div>
    </div>
  );
}

export default PropertyProfileImageManager;
