import React from "react";
import { useSearchParams } from "react-router-dom";
import SelectableTag from "@src/components/SelectableTag.jsx";
import { useSelector } from "react-redux";
import { doesUserOwnProperty } from "@src/store/features/user/slice.js";

function PropertyProfileDescription({ property }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isOwner = useSelector((state) =>
    doesUserOwnProperty(state, property.id),
  );

  const onDetailClick = (id) => {
    if (!isOwner) return;
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("edit", "true");
    newSearchParams.set("item", id);
    setSearchParams(newSearchParams);
  };

  if (!property.owner_blurb) return null;

  return (
    <div onClick={() => onDetailClick("owner_blurb")}>
      <h2 className="mb-3 font-attention text-xl text-neutral-800">About</h2>
      <div
        style={{ overflowWrap: "anywhere" }}
        className="flex flex-wrap gap-2 whitespace-pre-line text-sm leading-[160%] text-neutral-700"
      >
        {property.owner_blurb}
      </div>
    </div>
  );
}

export default PropertyProfileDescription;
