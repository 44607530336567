import { Navigate, useLocation } from "react-router-dom";
import { getAuthTokenFromStorage } from "@src/utils/misc";
import { useLayoutEffect, useState } from "react";

const LoggedOutRedirect = ({ children }) => {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useLayoutEffect(() => {
    const getToken = async () => {
      const token = await getAuthTokenFromStorage();
      setIsLoggedIn(!!token);
    };
    getToken();
  }, []);

  if (isLoggedIn === null) return null;

  if (!isLoggedIn) {
    const currentUrl = encodeURIComponent(location.pathname + location.search);
    return <Navigate to={`/signin?redirectTo=${currentUrl}`} />;
  }

  return children;
};

export default LoggedOutRedirect;
