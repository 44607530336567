import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";

export const PlaceAutocomplete = forwardRef(
  (
    {
      onPlaceSelect,
      className,
      onChange,
      textValue,
      placeholder,
      specificAddress,
    },
    ref,
  ) => {
    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary("places");
    const [isAutocompleteEnabled, setIsAutocompleteEnabled] = useState(true);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus(); // Focus the input when the component mounts
      }
    }, []);

    useImperativeHandle(ref, () => ({
      disableAutocomplete() {
        setIsAutocompleteEnabled(false);
        if (placeAutocomplete) {
          placeAutocomplete.setOptions({ types: [] }); // Disable autocomplete
        }
        inputRef.current.value = ""; // Clear the input value
        const event = new Event("input", { bubbles: true }); // Simulate an input event
        inputRef.current.dispatchEvent(event); // Trigger the input change event
      },
      enableAutocomplete() {
        setIsAutocompleteEnabled(true);
        if (placeAutocomplete) {
          placeAutocomplete.setOptions({ types: ["(regions)"] }); // Re-enable autocomplete
        }
      },
      focusInput() {
        inputRef.current?.focus(); // Focus the input
      },
    }));

    useEffect(() => {
      if (!places || !inputRef.current) return;

      const autocomplete = new places.Autocomplete(inputRef.current, {
        fields: [
          "type",
          "geometry",
          "name",
          "formatted_address",
          "address_components",
        ],
        componentRestrictions: { country: "us" },
        types: [
          "administrative_area_level_1",
          "locality",
          "postal_code",
          "premise",
          "subpremise",
        ], // Default types
      });

      setPlaceAutocomplete(autocomplete);
      return () => {
        // Cleanup the listener when component unmounts
        if (autocomplete) {
          window.google.maps.event.clearInstanceListeners(autocomplete);
        }
      };
    }, [places]);

    useEffect(() => {
      if (!placeAutocomplete) return;

      placeAutocomplete.addListener("place_changed", () => {
        onPlaceSelect(placeAutocomplete.getPlace());
      });
    }, [onPlaceSelect, placeAutocomplete]);

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      onChange(e);

      if (placeAutocomplete) {
        const looksLikeAddress = /^\d+[a-zA-Z\d\-]* /.test(inputValue);
        placeAutocomplete.setOptions({
          types: looksLikeAddress
            ? ["address"]
            : [
                "administrative_area_level_1",
                "locality",
                "postal_code",
                "premise",
                "subpremise",
              ],
        });
      }
    };

    // Add ZIP handling, ZIP place object to include city/state info
    const handleKeyDown = async (e) => {
      if (e.key === "Enter") {
        e.preventDefault();

        // Handle ZIP codes directly
        if (/^\d{5}$/.test(textValue.trim())) {
          const zipPlace = {
            formatted_address: textValue,
            address_components: [
              {
                types: ["postal_code"],
                short_name: textValue,
                long_name: textValue,
              },
            ],
            types: ["postal_code"],
            name: textValue,
            geometry: {
              location: {
                lat: () => null,
                lng: () => null,
              },
            },
          };
          onPlaceSelect(zipPlace);
          return;
        }

        // For non-ZIP inputs, just return and let place_changed handle it
        return;
      }
    };

    return (
      <input
        ref={inputRef}
        value={textValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className={className}
        placeholder={placeholder ?? "Address, city, state, or zip"}
      />
    );
  },
);

PlaceAutocomplete.displayName = "PlaceAutocomplete";
