import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserInfo, setUserToken } from "../store/features/user/slice";
import { getLoggedInUserInfo } from "@src/api/user";
import { getAuthTokenFromStorage } from "@src/utils/misc";
import { Preferences } from "@capacitor/preferences";
import {
  AUTHENTICATION_TOKEN,
  CARDS_ONBOARDING,
  EDIT_PROFILE_PROMPT_LAST_SHOWN,
  FIRST_PHOTO_PROMPT_LAST_SHOWN,
  HORIZONTAL_CARDS_SLIDESHOW,
  LAST_SERVICE_WORKER_TRIGGERED_RELOAD_TIME,
  ONBOARDING_SLIDESHOW,
  USER_ID,
  HAS_OPENED_SEARCH_PANEL,
} from "@src/utils/constants";
import { loadInitialData } from "@src/store/features/user/service";

export const Initializer = ({ children }) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const getUser = async () => {
      const token = await getAuthTokenFromStorage();
      if (token) {
        try {
          const response = await getLoggedInUserInfo();
          dispatch(setUserInfo(response));
          dispatch(setUserToken(token)); // This uses the `token` correctly
        } catch (error) {
          console.log(error);
        }
      }
    };
    getUser();

    const migrateFromLocalStorageToPreferences = async (key) => {
      const localStorageValue = localStorage.getItem(key);
      const { value } = await Preferences.get({ key });

      if (localStorageValue && value) localStorage.removeItem(key);
      if (localStorageValue) {
        Preferences.set({
          key,
          value:
            typeof localStorageValue === "string"
              ? localStorageValue
              : JSON.stringify(localStorageValue),
        });
      }
    };

    // Remove this and the function when localStorage is being completely removed
    [
      AUTHENTICATION_TOKEN,
      USER_ID,
      CARDS_ONBOARDING,
      ONBOARDING_SLIDESHOW,
      EDIT_PROFILE_PROMPT_LAST_SHOWN,
      FIRST_PHOTO_PROMPT_LAST_SHOWN,
      HORIZONTAL_CARDS_SLIDESHOW,
      LAST_SERVICE_WORKER_TRIGGERED_RELOAD_TIME,
      HAS_OPENED_SEARCH_PANEL,
    ].forEach((key) => migrateFromLocalStorageToPreferences(key));

    dispatch(loadInitialData());
  }, []);

  return children;
};
