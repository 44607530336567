import React, { useState, useRef, useEffect } from "react";
import architectural_styles from "../../data/architectural_styles.js";
import { MdAdd, MdCheck, MdOutlineAdd, MdOutlineCottage } from "react-icons/md";
import HomeOultine from "@src/assets/svgs/HomeOultine.jsx";
import { AnimatePresence, motion } from "framer-motion";
import CottageIcon from "@src/assets/svgs/CottageIcon.jsx";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";

function SelectArchitecturalStyles({
  value,
  onChange,
  prefix,
  writeInStyle,
  gridPadding,
  allowMultiple = false,
}) {
  const [selectedArchitectureStyles, setSelectedArchitectureStyles] =
    useState(value);

  const writeInInputRef = useRef(null);
  const [isShowingWriteInField, setIsShowingWriteInField] = useState(false);
  const [localWriteInStyle, setLocalWriteInStyle] = useState(writeInStyle);
  const [open, setOpen] = useState(false);

  const MAX_CHARACTERS = 25;

  const toggleArchitecturalStyle = (styleId) => {
    if (styleId === 12 && !allowMultiple) {
      //show write in field
      if (localWriteInStyle && !isSelectedStyle(12)) {
        console.log("reached one");
        //setIsShowingWriteInField(true);
        setSelectedArchitectureStyles([12]);
        onChange([12], localWriteInStyle);
      } else {
        console.log("reached two");
        setSelectedArchitectureStyles([12]);
        setIsShowingWriteInField(!isShowingWriteInField);
      }
    } else {
      console.log("reached three");
      let newStyles = [];
      if (!allowMultiple) {
        if (selectedArchitectureStyles.includes(styleId)) {
          newStyles = [10000];
        } else {
          newStyles = [styleId];
        }
      } else {
        if (selectedArchitectureStyles.includes(styleId)) {
          newStyles = selectedArchitectureStyles.filter((id) => id !== styleId);
        } else {
          newStyles = [...selectedArchitectureStyles, styleId];
        }
      }

      setSelectedArchitectureStyles(newStyles);
      if (onChange) {
        const styleIds = newStyles.map((id) => {
          if (id === 10000) return 10000;
          const style = architectural_styles.find((style) => style.id === id);
          return style.id;
        });

        onChange(styleIds);
      }
    }
  };

  const getCurrentArchitecturalStyles = () => {
    return architectural_styles.filter((style) => {
      if (allowMultiple && style.id === 0) return false;
      return style;
    });
  };

  const onStyleChanged = (e) => {
    const styleId = parseInt(e.target.dataset.styleId);
    // if (styleId === 12) {
    //   setLocalWriteInStyle("");
    // }
    toggleArchitecturalStyle(styleId);
  };

  const isSelectedStyle = (styleId) => {
    //if (writeInStyle && styleId === 12) return true;
    return selectedArchitectureStyles.includes(styleId);
  };

  const onWriteInStyleChange = (e) => {
    const value = e.target.value;
    if (value.length > MAX_CHARACTERS) return;
    setSelectedArchitectureStyles([12]);
    setLocalWriteInStyle(value);
    onChange([12], value);
  };

  const onKeyDown = (e) => {
    console.log("reached");
    if (e.key === "Enter") {
      e.preventDefault();
      if (localWriteInStyle !== null) {
        setIsShowingWriteInField(false);
      }
    }
  };

  useEffect(() => {
    if (!allowMultiple && isShowingWriteInField) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isShowingWriteInField]);

  return (
    <div className="flex flex-col items-center @container">
      {/*<div className="grid w-full auto-rows-auto grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))] gap-3 gap-y-5 sm:pt-4 md:grid-cols-[repeat(auto-fit,_minmax(10rem,_1fr))] md:gap-8 md:gap-y-10">*/}
      <div
        className={`grid w-full auto-rows-auto grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))] gap-4 gap-y-5 @xs:grid-cols-[repeat(auto-fit,_minmax(7rem,_1fr))] @md:grid-cols-[repeat(auto-fit,_minmax(8rem,_1fr))] ${gridPadding ? gridPadding : ""}}`}
      >
        {getCurrentArchitecturalStyles().map((style) => (
          <div
            key={style.id}
            className="relative h-fit w-fit overflow-hidden rounded-2xl text-center transition-colors sm:hover:bg-primary-500/20"
          >
            <input
              data-style-id={style.id}
              checked={isSelectedStyle(style.id)}
              id={`${prefix}_${style.id}`}
              className="peer absolute hidden"
              type="checkbox"
              onChange={(e) => onStyleChanged(e)}
            />
            <label
              htmlFor={`${prefix}_${style.id}`}
              className="max-w-30 relative block flex w-full cursor-pointer flex-col items-center justify-center gap-2 p-6 pb-2 pt-3 font-content"
            >
              {style.id === 12 ? (
                <div className="relative">
                  <img
                    src={style.image}
                    alt={style.label}
                    className="w-full mix-blend-darken"
                  />
                  <CottageIcon className="absolute left-1/2 top-1/2 h-[50%] w-[50%] -translate-x-1/2 -translate-y-[35%] fill-neutral-400" />
                </div>
              ) : (
                <img
                  src={style.image}
                  alt={style.label}
                  className="w-full mix-blend-darken"
                />
              )}

              <span className="max-w-full text-xs leading-4 text-neutral-700">
                {style.id === 12 ? (
                  <>
                    {writeInStyle ? (
                      <span className="break-all">{writeInStyle}</span>
                    ) : (
                      <span className="break-all">
                        {localWriteInStyle || style.label}
                      </span>
                    )}
                  </>
                ) : (
                  <>{style.label}</>
                )}
              </span>
            </label>
            <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-full bg-primary-500 opacity-0 mix-blend-multiply shadow-custom-inset transition-all peer-checked:opacity-20"></div>
            <div className="pointer-events-none absolute right-2 top-2 h-fit w-fit rounded-full bg-primary-500/20 p-1 opacity-0 peer-checked:opacity-100">
              <MdCheck className="opacity-60" />
            </div>
          </div>
        ))}
      </div>

      <Dialog.Root
        open={isShowingWriteInField}
        setOpenChange={setIsShowingWriteInField}
      >
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-50 bg-black/50 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
          <Dialog.Content
            onClick={() => setIsShowingWriteInField(false)}
            className="fixed left-1/2 top-0 z-50 flex h-dvh w-dvw -translate-x-1/2 flex-col justify-start data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms] md:justify-center"
          >
            <VisuallyHidden>
              <Dialog.Title>
                Please enter your own architectural style
              </Dialog.Title>
              <Dialog.Description>
                When selecting other we ask that you specify the architectural
                style of your home.
              </Dialog.Description>
            </VisuallyHidden>
            <div
              className="mx-auto mt-6 flex w-[min(calc(100%-3rem),24rem)] flex-col items-center rounded-lg bg-white p-6 shadow-xl md:mt-0 md:p-8"
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="mx-auto block max-w-[26ch] text-pretty text-center font-attention text-2xl text-neutral-900 md:text-2xl md:leading-8 md:text-neutral-700">
                Please enter your own architectural style
              </h3>
              <input
                ref={writeInInputRef}
                onPointerDown={(e) => e.stopPropagation()}
                type="text"
                value={writeInStyle || localWriteInStyle || ""}
                onChange={onWriteInStyleChange}
                onKeyDown={onKeyDown}
                placeholder="Little house"
                className="mt-6 w-full rounded-md p-3 font-content text-xl text-neutral-900 outline-none ring-1 ring-neutral-300 transition-all focus:ring-primary-500"
              />
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}

export default SelectArchitecturalStyles;
