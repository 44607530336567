import { Outlet } from "react-router-dom";
import DiscoverTabs from "../../components/DiscoverTabs.jsx";
import { MdKeyboardArrowDown, MdLocationOn, MdSearch } from "react-icons/md";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FilterRangeDisplay from "@src/components/filters/range/display/FilterRangeDisplay.jsx";
import * as Dialog from "@radix-ui/react-dialog";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import {
  setIsFilterSearching,
  setLastSearchValues,
} from "@src/store/features/search/slice.js";
import price_range from "@src/data/price_range.js";
import square_feet from "@src/data/square_feet.js";
import bedroom_options from "@src/data/bedroom_options.js";
import bathroom_options from "@src/data/bathroom_options.js";
import { searchProperties } from "@src/store/features/search/service.js";
import FilterRangeInput from "@src/components/filters/range/input/FilterRangeInput.jsx";
import FilterRadioGroupInput from "@src/components/filters/radioGroup/input/FilterRadioGroupInput.jsx";
import SelectPerks from "@src/components/selectPerks/SelectPerks.jsx";
import FilterPerksInput from "@src/components/filters/perks/input/FilterPerksInput.jsx";
import FilterArchitecturalStylesInput from "@src/components/filters/architecturalStyles/input/FilterArchitecturalStylesInput.jsx";
import FilterSearchAutocomplete from "@src/components/filters/search/autocomplete/FilterSearchAutocomplete.jsx";
import property_perks from "@src/data/property_perks.js";
import SparkleIcon from "@src/assets/svgs/SparkleIcon.jsx";
import ReactGA from "react-ga4";

import image1 from "@src/assets/home_images/streetview-tiny.jpg";
import image2 from "@src/assets/home_images/streetview-1-tiny.jpg";
import image3 from "@src/assets/home_images/streetview-2-tiny.jpg";
import image4 from "@src/assets/home_images/streetview-3-tiny.jpg";
import image5 from "@src/assets/home_images/streetview-4-tiny.jpg";
import image6 from "@src/assets/home_images/streetview-5-tiny.jpg";

import image7 from "@src/assets/home_images/streetview-6-tiny.jpg";
import image8 from "@src/assets/home_images/streetview-7-tiny.jpg";
import image9 from "@src/assets/home_images/streetview-8-tiny.jpg";
import image10 from "@src/assets/home_images/streetview-9-tiny.jpg";
import image11 from "@src/assets/home_images/streetview-10-tiny.jpg";
import image12 from "@src/assets/home_images/streetview-11-tiny.jpg";
import { resetScroll } from "@src/utils/misc.js";

function Discover() {
  const dispatch = useDispatch();
  const { lastSearchValues, newResults, isFilterSearching, properties } =
    useSelector((state) => state.search);

  const [recentValueChanges, setRecentValueChanges] = useState(null);
  const [openStates, setOpenStates] = useState({});

  const handleOpenChange = (id, isOpen) => {
    console.log("openStates", openStates);
    setOpenStates((prevState) => ({ ...prevState, [id]: isOpen }));
  };

  const infiniteScrollImagesTop = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
  ];

  const infiniteScrollImagesBottom = [
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  ];

  const priceRangeValues =
    lastSearchValues?.priceRangeSetting || price_range.defaultValues;

  const priceRangeLabel = (
    <FilterRangeDisplay
      rangeValues={priceRangeValues}
      prepend="$"
      min={price_range.min}
      max={price_range.max}
      steps={price_range.values}
    />
  );

  const squareFootageValues =
    lastSearchValues?.squareFootageSetting || square_feet.defaultValues;
  const squareFootageLabel = (
    <>
      <FilterRangeDisplay
        rangeValues={squareFootageValues}
        format=""
        separator="-"
        min={square_feet.min}
        max={square_feet.max}
        steps={square_feet.values}
      />{" "}
      <span>Sqft.</span>
    </>
  );

  let locationLabel = "Nationwide, USA";

  if (lastSearchValues?.selectedPlace) {
    const hasCity = lastSearchValues?.selectedPlace?.city ? true : false;
    const hasState = lastSearchValues?.selectedPlace?.state ? true : false;
    const hasZip = lastSearchValues?.selectedPlace?.zip ? true : false;

    if (hasZip) {
      locationLabel = lastSearchValues?.selectedPlace?.zip;
    } else {
      locationLabel = `${hasCity ? lastSearchValues?.selectedPlace?.city : ""}${hasCity && hasState ? ", " : ""}${hasState ? lastSearchValues?.selectedPlace?.state : ""}`;
    }
  }

  const bedroomValues = lastSearchValues?.beds || bedroom_options.defaultValues;
  const bedroomValuesString = bedroom_options.values[bedroomValues[0]];

  const bedroomsLabel = `${bedroomValuesString !== "Any" ? bedroomValuesString : ""} ${bedroomValues[0] > 1 || bedroomValuesString === "Any" ? "Beds" : "Bed"}`;

  const bathroomValues =
    lastSearchValues?.baths || bathroom_options.defaultValues;
  const bathroomValuesString = bathroom_options.values[bathroomValues[0]];
  const bathroomsLabel = `${bathroomValuesString !== "Any" ? bathroomValuesString : ""} ${bathroomValues[0] > 1 || bathroomValuesString === "Any" ? "Baths" : "Bath"}`;

  const perksValues = lastSearchValues?.selectedPropertyPerks || [];
  const perksLabel = `${lastSearchValues?.selectedPropertyPerks.length || "Perks"}  ${lastSearchValues?.selectedPropertyPerks.length ? (lastSearchValues?.selectedPropertyPerks.length > 1 ? "Perks" : "Perk") : ""}`;
  const stylesLabel = `${lastSearchValues?.selectedArchitecturalStyles.length || "Styles"}  ${lastSearchValues?.selectedArchitecturalStyles.length ? (lastSearchValues?.selectedArchitecturalStyles.length > 1 ? "Styles" : "Style") : ""}`;

  const onInteractOutsideModal = (e) => {
    if (isFilterSearching) {
      e.preventDefault();
    }
  };

  const onApplyFilter = async ({ valuesToSave, valuesToSearch }) => {
    dispatch(setIsFilterSearching(true));

    let placeValuesToSearch = lastSearchValues?.selectedPlace || null;

    if (
      valuesToSearch.city ||
      valuesToSearch.state ||
      valuesToSearch.country ||
      valuesToSearch.administrative_area_level_1 ||
      valuesToSearch.zip
    ) {
      placeValuesToSearch = valuesToSearch;
    }

    let searchData = placeValuesToSearch ? { ...placeValuesToSearch } : {};

    const priceRange =
      lastSearchValues?.priceRangeSetting || price_range.defaultValues;
    searchData.price_min = String(price_range.values[priceRange[0]]);
    searchData.price_max = String(price_range.values[priceRange[1]]);
    const squareFootage =
      lastSearchValues?.squareFootageSetting || square_feet.defaultValues;
    searchData.area_min = String(square_feet.values[squareFootage[0]]);
    searchData.area_max = String(square_feet.values[squareFootage[1]]);
    const bedrooms = lastSearchValues?.beds || bedroom_options.defaultValues;
    searchData.beds = String(bedroom_options.values[bedrooms[0]]).replace(
      /\+$/,
      "",
    );
    const bathrooms = lastSearchValues?.baths || bathroom_options.defaultValues;
    searchData.baths = String(bathroom_options.values[bathrooms[0]]).replace(
      /\+$/,
      "",
    );
    searchData.style = lastSearchValues?.selectedArchitecturalStyles || [];

    //if lastSearchValues.selectedPropertyPerks is an array and it is not empty
    //we need to loop through each string value and set it as a property on searchData with a value of boolean true
    if (
      Array.isArray(lastSearchValues?.selectedPropertyPerks) &&
      lastSearchValues?.selectedPropertyPerks.length > 0
    ) {
      lastSearchValues?.selectedPropertyPerks.forEach((perk) => {
        searchData[perk] = true;
      });
    }

    if (valuesToSearch.baths)
      valuesToSearch.baths = valuesToSearch.baths.replace(/\+$/, "");

    if (valuesToSearch.beds)
      valuesToSearch.beds = valuesToSearch.beds.replace(/\+$/, "");

    const updatedSearchData = { ...searchData, ...valuesToSearch };

    // there's a special case for property perks we need to handle
    // they are stored as keys on the searchData object so we need to first
    // check if the filter was for property perks, we do this by checking if
    // valuesToSave has a key of selectedPropertyPerks. If it does, we need to
    // loop through the property_perks array and if it is not in the valuesToSave.selectedPropertyPerks
    // array, we need to remove it from the searchData object
    if (valuesToSave.selectedPropertyPerks) {
      property_perks.forEach((perk) => {
        if (!valuesToSave.selectedPropertyPerks.includes(perk.id)) {
          delete updatedSearchData[perk.id];
        }
      });
    }

    setRecentValueChanges(valuesToSave);

    dispatch(searchProperties(updatedSearchData));
  };

  useEffect(() => {
    if (!recentValueChanges) return;
    const newLastSearchValues = { ...lastSearchValues, ...recentValueChanges };
    dispatch(setLastSearchValues(newLastSearchValues));
    setRecentValueChanges(null);
    setOpenStates({});
    ReactGA.event("view_search_results");

    resetScroll();
  }, [newResults]);

  const filters = [
    {
      id: "location",
      displayComponent: (
        <FilterButton label={locationLabel} icon={<MdLocationOn />} />
      ),
      inputComponent: (
        <FilterSearchAutocomplete
          onApplyFilter={({ valuesToSave, valuesToSearch }) => {
            onApplyFilter({ valuesToSave, valuesToSearch });
          }}
        />
      ),
    },
    {
      id: "priceRange",
      displayComponent: <FilterButton label={priceRangeLabel} />,
      inputComponent: (
        <FilterRangeInput
          label="Price Range"
          rangeValues={price_range.values}
          min={price_range.min}
          max={price_range.max}
          initialRangeValues={priceRangeValues}
          format="$" // Prepend dollar sign
          onApplyFilter={({ valuesToSave, valuesToSearch }) =>
            onApplyFilter({
              valuesToSave: { priceRangeSetting: valuesToSave.setting },
              valuesToSearch: {
                price_min: valuesToSearch.min,
                price_max: valuesToSearch.max,
              },
            })
          }
        />
      ),
    },
    {
      id: "squareFootage",
      displayComponent: <FilterButton label={squareFootageLabel} />,
      inputComponent: (
        <FilterRangeInput
          label="Square Feet"
          rangeValues={square_feet.values}
          min={square_feet.min}
          max={square_feet.max}
          initialRangeValues={squareFootageValues}
          format="" // Prepend dollar sign
          onApplyFilter={({ valuesToSave, valuesToSearch }) =>
            onApplyFilter({
              valuesToSave: { squareFootageSetting: valuesToSave.setting },
              valuesToSearch: {
                area_min: valuesToSearch.min,
                area_max: valuesToSearch.max,
              },
            })
          }
        />
      ),
    },
    {
      id: "bedrooms",
      displayComponent: <FilterButton label={bedroomsLabel} />,
      inputComponent: (
        <FilterRadioGroupInput
          label="Bedrooms"
          options={bedroom_options.values}
          initialValues={bedroomValues}
          onApplyFilter={({ valuesToSave, valuesToSearch }) =>
            onApplyFilter({
              valuesToSave,
              valuesToSearch,
            })
          }
          valueKey="beds"
        />
      ),
    },
    {
      id: "bathrooms",
      displayComponent: <FilterButton label={bathroomsLabel} />,
      inputComponent: (
        <FilterRadioGroupInput
          label="Bathrooms"
          options={bathroom_options.values}
          initialValues={bathroomValues}
          onApplyFilter={({ valuesToSave, valuesToSearch }) =>
            onApplyFilter({
              valuesToSave,
              valuesToSearch,
            })
          }
          valueKey="baths"
        />
      ),
    },

    {
      id: "styles",
      displayComponent: <FilterButton label={stylesLabel} />,
      inputComponent: (
        <FilterArchitecturalStylesInput onApplyFilter={onApplyFilter} />
      ),
    },
    {
      id: "perks",
      displayComponent: <FilterButton label={perksLabel} />,
      inputComponent: <FilterPerksInput onApplyFilter={onApplyFilter} />,
    },
  ];

  return (
    <>
      <div
        className={`relative mt-16 w-full ${properties.length === 0 && "hidden"}`}
      >
        <div className="pointer-events-none absolute left-0 top-0 z-10 h-full w-10 bg-gradient-to-r from-white to-transparent"></div>
        <div className="pointer-events-none absolute right-0 top-0 z-10 h-full w-10 bg-gradient-to-l from-white to-transparent"></div>
        <div className="hide-scrollbar relative flex w-full select-none gap-4 overflow-y-auto whitespace-nowrap px-6 py-2.5 text-xs font-normal text-black md:ml-auto md:mr-auto md:mt-12 md:max-w-fit md:text-sm">
          {lastSearchValues?.searchMode === "ai" ? (
            <span className="flex w-full items-center justify-center gap-1 text-neutral-600">
              <SparkleIcon className="h-2.5 w-2.5 fill-current" /> Showing AI
              search results
            </span>
          ) : (
            <>
              {filters.map((filter) => (
                <Dialog.Root
                  modal={filter.id !== "location"}
                  onPointerDownOutside={(e) => e.preventDefault()}
                  key={filter.id}
                  open={!!openStates[filter.id]} // Convert undefined to false
                  onOpenChange={(isOpen) => handleOpenChange(filter.id, isOpen)}
                >
                  <Dialog.Trigger
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(setIsFilterSearching(false));
                    }}
                    className="flex items-center gap-0"
                  >
                    {filter.displayComponent}
                  </Dialog.Trigger>
                  <Dialog.Portal>
                    <Dialog.Overlay className="fixed inset-0 z-40 bg-black bg-opacity-60 data-[state=closed]:animate-[fade-out_300ms] data-[state=open]:animate-[fade-in_300ms]" />
                    <Dialog.Content
                      onCloseAutoFocus={(e) => e.preventDefault()}
                      onPointerDownOutside={(e) => {
                        if (filter.id !== "location") {
                          onInteractOutsideModal(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                      onInteractOutside={(e) => {
                        if (filter.id !== "location") {
                          onInteractOutsideModal(e);
                        } else {
                          e.preventDefault();
                        }
                      }}
                      className={`fixed left-1/2 z-50 mx-auto flex max-h-[80dvh] min-h-44 w-full -translate-x-1/2 flex-col ${filter.id === "location" ? "top-0 h-dvh w-full rounded-none bg-transparent p-4 data-[state=closed]:animate-[slight-slide-down-fade-out_300ms] data-[state=open]:animate-[slight-slide-up-fade-in_300ms]" : "bottom-0 h-fit max-w-xl items-end justify-end data-[state=closed]:animate-[slide-down-fade-out_300ms] data-[state=open]:animate-[slide-up-fade-in_300ms]"}`}
                    >
                      <div
                        className={`flex max-h-[80dvh] min-h-44 w-full flex-col pb-12 ${filter.id === "location" ? "" : "rounded-tl-xl rounded-tr-xl bg-white p-6 pt-6 md:mb-5 md:rounded-xl"}`}
                      >
                        {filter.id === "location" && (
                          <Dialog.Close
                            className={`absolute left-0 right-0 top-[-100dvh] z-0 h-[200dvh] bg-black bg-opacity-60 ${isFilterSearching && "pointer-events-none"}`}
                          ></Dialog.Close>
                        )}

                        <VisuallyHidden>
                          <Dialog.Title>{filter.id}</Dialog.Title>
                          <Dialog.Description>
                            A filter for changing the houses that are listed.
                          </Dialog.Description>
                        </VisuallyHidden>
                        {filter.inputComponent}
                      </div>
                    </Dialog.Content>
                  </Dialog.Portal>
                </Dialog.Root>
              ))}
            </>
          )}
        </div>
      </div>
      {properties.length === 0 ? (
        <div className="mt-3 flex flex-1 flex-col items-center justify-center text-pretty text-center sm:mt-0 md:gap-8">
          <div className="flex w-full flex-col items-center gap-6 xs:gap-12">
            <div className="relative w-full max-w-6xl select-none overflow-hidden opacity-40">
              <div className="infinite-scroll-left flex h-full w-fit items-center">
                {["a", "b"].map((passId) => (
                  <Fragment key={passId}>
                    {infiniteScrollImagesTop.map((image, index) => (
                      <div
                        key={`${image}${passId}`}
                        className="relative mx-1 aspect-video h-[12dvh] max-h-44 shrink-0 select-none overflow-hidden rounded-lg bg-blue-50 xs:mx-2 sm:mx-4 sm:h-[17dvh] sm:rounded-xl"
                      >
                        <img
                          key={`${image}${passId}${index}`}
                          src={image}
                          alt=""
                          loading="lazy"
                          className="pointer-events-none absolute h-full w-full select-none object-cover"
                        />
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>
              <div
                data-grad-left
                className="absolute left-0 top-0 h-full w-20 bg-gradient-to-r from-white to-transparent sm:w-48"
              ></div>
              <div
                data-gradient-right
                className="absolute right-0 top-0 h-full w-20 bg-gradient-to-l from-white to-transparent sm:w-48"
              ></div>
            </div>
            <div className="flex flex-col gap-6">
              <h2 className="mx-auto w-[min(calc(100dvw-6rem),40ch)] font-attention text-2xl leading-[130%] text-neutral-900 xs:text-[clamp(1.4rem,6.5vw,2.4rem)]">
                Discover off-market homes you won’t find anywhere else.
              </h2>
              <p className="mx-auto w-[min(calc(100dvw-6rem),60ch)] text-balance text-sm font-light leading-[130%] text-neutral-600 xs:text-[clamp(1em,3vw,1.4rem)]">
                Tap the
                <MdSearch className="ml-1 inline fill-black text-lg xs:text-[clamp(1em,3vw,1.4rem)]" />
                search icon in the top right to begin.
              </p>
            </div>

            <div className="relative w-full max-w-6xl select-none overflow-hidden opacity-40">
              <div className="infinite-scroll-right flex h-full w-fit select-none items-center">
                {["c", "d"].map((passId) => (
                  <Fragment key={passId}>
                    {infiniteScrollImagesBottom.map((image, index) => (
                      <div
                        key={`${image}${passId}`}
                        className="relative mx-1 aspect-video h-[12dvh] max-h-44 shrink-0 overflow-hidden rounded-lg bg-blue-50 xs:mx-2 sm:mx-4 sm:h-[17dvh] sm:rounded-xl"
                      >
                        <img
                          key={`${image}${passId}${index}`}
                          src={image}
                          alt=""
                          loading="lazy"
                          className="pointer-events-none absolute h-full w-full object-cover"
                        />
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>
              <div
                data-grad-left
                className="absolute left-0 top-0 h-full w-20 bg-gradient-to-r from-white to-transparent sm:w-48"
              ></div>
              <div
                data-gradient-right
                className="absolute right-0 top-0 h-full w-20 bg-gradient-to-l from-white to-transparent sm:w-48"
              ></div>
            </div>
          </div>
        </div>
      ) : (
        <Outlet />
      )}
      <DiscoverTabs />
    </>
  );
}

export default Discover;

function FilterButton({ label, icon }) {
  return (
    <>
      {icon && <span className="mr-0.5">{icon}</span>}
      <span>{label}</span>
      <MdKeyboardArrowDown className="shrink-0 translate-y-[0.5px] fill-neutral-800 text-sm text-current" />
    </>
  );
}
