import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import {
  setIsFilterSearching,
  setIsTraditionalSearchingFailure,
} from "@src/store/features/search/slice.js";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { formatGoogleAPIPlace } from "@src/utils/misc.js";
import { PropertySearchInput } from "@src/components/propertySearchInput/PropertySearchInput";
import { PLACES } from "@src/utils/constants";
import { IoMdCloseCircle } from "react-icons/io";
import { PlaceAutocomplete } from "@src/components/placeAutocomplete/PlaceAutocomplete";

function FilterSearchAutocomplete({ onApplyFilter }) {
  const inputRef = useRef(null);

  const {
    isTraditionalSearchingFailure,
    isFilterSearching,
    currentDisplayProperty,
    lastSearchValues,
  } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [localTraditionalSearchString, setLocalTraditionalSearchString] =
    useState(lastSearchValues?.traditionalSearchString || "");

  const [localSelectedPlace, setLocalSelectedPlace] = useState(null);

  const onPlaceSelected = (place) => {
    if (!place?.formatted_address) return;
    setLocalSelectedPlace(place);
  };

  const onSearchQueryChange = async (e) => {
    dispatch(setIsTraditionalSearchingFailure(false));
    setLocalTraditionalSearchString(e.target.value);
  };

  useEffect(() => {
    dispatch(setIsTraditionalSearchingFailure(false));
  }, []);

  useEffect(() => {
    dispatch(setIsFilterSearching(false));
  }, [isTraditionalSearchingFailure]);

  // useEffect(() => {
  //   if (currentDisplayProperty) {
  //     setSearchParams({ showing: currentDisplayProperty.id });
  //   }
  // }, [currentDisplayProperty]);

  useEffect(() => {
    if (localSelectedPlace) {
      setLocalTraditionalSearchString(localSelectedPlace.formatted_address);
      onApplyFilter({
        valuesToSave: {
          selectedPlace: formatGoogleAPIPlace({ place: localSelectedPlace }),
          traditionalSearchString: localSelectedPlace.formatted_address,
        },
        valuesToSearch: formatGoogleAPIPlace({ place: localSelectedPlace }),
      });
    }
  }, [localSelectedPlace]);

  const [isLoading, setIsLoading] = useState(false);

  const handleSearchQueryChange = (query) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      <div className="flex w-full flex-col gap-14">
        <div className="z-10 mx-auto mt-8 w-[min(100%-1rem,35rem)] rounded-xl bg-white p-5 pb-6 pt-3">
          <header
            className={`${isFilterSearching && "opacity-30"} mb-3 flex flex-row justify-start`}
          >
            <label className="font-semibold text-gray-600">
              Change Location
            </label>
          </header>
          <div className="rounded-md ring-0 ring-transparent transition-shadow focus-within:ring-2 focus-within:ring-[#8E9991]/40 focus-within:ring-offset-2">
            <div className="relative w-full overflow-hidden rounded-md">
              {isFilterSearching && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  <div className="h-6 w-6 animate-spin rounded-full border border-2 border-black/10 border-t-sage-700 transition duration-100" />
                </div>
              )}

              <PlaceAutocomplete
                textValue={localTraditionalSearchString}
                className={`${isFilterSearching && "pointer-events-none opacity-30"} inline-block w-full rounded-md border border-[#8E9991] p-3 pr-12 font-content outline-0 transition-all focus:border-[#8E9991]/50`}
                onPlaceSelect={onPlaceSelected}
                onChange={(e) => onSearchQueryChange(e)}
                ref={inputRef}
              />
              {localTraditionalSearchString && (
                <button
                  onClick={() => {
                    setLocalTraditionalSearchString("");
                    dispatch(setIsTraditionalSearchingFailure(false));
                    if (inputRef.current) {
                      inputRef.current.disableAutocomplete(); // Clear the autocomplete suggestions and disable autocomplete
                      setTimeout(() => {
                        inputRef.current.enableAutocomplete(); // Re-enable autocomplete after hiding the dropdown
                        inputRef.current.focusInput(); // Focus the input
                      }, 150); // Adjust delay if necessary
                    }
                  }}
                  className={`${isFilterSearching && "pointer-events-none opacity-30"} absolute right-0 top-0 flex h-12 w-12 items-center justify-center`}
                >
                  <IoMdCloseCircle className="h-6 w-6 fill-neutral-300" />
                </button>
              )}
            </div>
          </div>
          {isTraditionalSearchingFailure && (
            <span className="mt-4 block w-full rounded-lg bg-orange-300/40 p-3 px-2 text-center text-sm text-orange-800">
              Sorry, your search returned no results. Please adjust your filters
              and try again.
            </span>
          )}
          {/*<PropertySearchInput*/}
          {/*  places={PLACES}*/}
          {/*  handleSearchQueryChange={handleSearchQueryChange}*/}
          {/*  isLoading={isLoading}*/}
          {/*/>*/}
        </div>
      </div>
    </>
  );
}

export default FilterSearchAutocomplete;
