const checklist_options = [
  {
    id: "interior",
    name: "Interior",
    buckets: [
      { id: "living_room", name: "Living room", tags: ["Living room"] },
      { id: "family_room", name: "Family room", tags: ["Family room"] },
      { id: "dining_room", name: "Dining room", tags: ["Dining room"] },
      { id: "kitchen", name: "Kitchen", tags: ["Kitchen", "Pantry"] },
      // {
      //   id: "primary_bedroom",
      //   name: "Primary Bedroom",
      //   tags: ["Primary Bedroom"],
      // },
      { id: "bedrooms", name: "Bedrooms", tags: ["Bedroom"] },
      { id: "bathrooms", name: "Bathrooms", tags: ["Bathroom"] },
      { id: "office", name: "Office", tags: ["Office"] },
      { id: "laundry_room", name: "Laundry room", tags: ["Laundry room"] },
      { id: "basement", name: "Basement", tags: ["Basement"] },
      { id: "attic", name: "Attic", tags: ["Attic"] },
      { id: "garage", name: "Garage", tags: ["Garage"] },
      { id: "hallway", name: "Hallway", tags: ["Hallway"] },
      { id: "stairs", name: "Stairs", tags: ["Stairs"] },
      { id: "closet", name: "Closet", tags: ["Closet"] },
    ],
  },
  {
    id: "exterior",
    name: "Exterior",
    buckets: [
      { id: "front_yard", name: "Front yard", tags: ["Front yard"] },
      { id: "back_yard", name: "Back yard", tags: ["Back yard"] },
      { id: "patio", name: "Patio", tags: ["Patio"] },
      { id: "deck", name: "Deck", tags: ["Deck"] },
      { id: "porch", name: "Porch", tags: ["Porch"] },
      { id: "pool", name: "Pool", tags: ["Pool"] },
      { id: "garden", name: "Garden", tags: ["Garden"] },
      { id: "driveway", name: "Driveway", tags: ["Driveway"] },
      { id: "garage", name: "Garage", tags: ["Garage"] },
      { id: "shed", name: "Shed", tags: ["Shed"] },
    ],
  },
  {
    id: "details",
    name: "Details",
    buckets: [
      { id: "tilework", name: "Tilework", tags: ["Tilework"] },
      { id: "lighting", name: "Lighting", tags: ["Lighting"] },
      { id: "moulding", name: "Moulding", tags: ["Moulding"] },
    ],
  },
];

export default checklist_options;
