import { Preferences } from "@capacitor/preferences";
import { isFulfilled } from "@reduxjs/toolkit";
import SignInModal from "@src/components/signInModal/SignInModal";
import { likeHome, unlikeHome } from "@src/store/features/user/service";
import {
  FAVORITE_BUTTON_ACTION,
  PROPERTY_TO_LIKE,
  SIGNIN_MODAL_TYPE,
} from "@src/utils/constants";
import { useEffect, useState } from "react";
import { MdFavorite, MdOutlineFavoriteBorder } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function PropertyProfileFavoriteButton({
  isLiked,
  propertyId,
  callback = () => {},
}) {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [signinModalType, setSigninModalType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const commonStyles = "h-6 w-6 translate-y-[0.8px]";

  const handleLike = async (propertyToLike = undefined) => {
    setIsLoading(true);
    const response = await dispatch(likeHome(propertyToLike || propertyId));
    callback({
      fulfilled: isFulfilled(response),
      action: FAVORITE_BUTTON_ACTION.LIKE,
    });

    if (!isFulfilled(response)) {
      toast.error("Failed to like this property");
    }
    setIsLoading(false);
  };

  const handleUnlike = async () => {
    setIsLoading(true);
    const response = await dispatch(unlikeHome(propertyId));
    callback({
      fulfilled: isFulfilled(response),
      action: FAVORITE_BUTTON_ACTION.UNLIKE,
    });

    if (!isFulfilled(response)) {
      toast.error("Failed to unlike this property");
    }

    setIsLoading(false);
  };

  const onPressFavorite = (e) => {
    e.stopPropagation();
    if (isLoading) return null;

    if (!isLoggedIn) {
      setRedirectUrl(`/profile/${propertyId}`);

      Preferences.set({
        key: PROPERTY_TO_LIKE,
        value: JSON.stringify(propertyId),
      });
      setSigninModalType(SIGNIN_MODAL_TYPE.FAVORITE);
      return;
    }

    if (isLiked) handleUnlike();
    else handleLike();
  };

  const onClickSignModalOverlay = (e) => {
    //e.stopPropagation();
    Preferences.remove({ key: PROPERTY_TO_LIKE });
    setSigninModalType("");
    setRedirectUrl("");
  };

  useEffect(() => {
    const getPropertyToLike = async () => {
      const { value: propertyToLike } = await Preferences.get({
        key: PROPERTY_TO_LIKE,
      });

      if (propertyToLike) handleLike(propertyToLike);
      Preferences.remove({ key: PROPERTY_TO_LIKE });
    };

    if (isLoggedIn) getPropertyToLike();
  }, [isLoggedIn]);

  return (
    <>
      <div
        className="flex aspect-square h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-black/60 p-1.5"
        onClick={onPressFavorite}
      >
        {isLiked ? (
          <MdFavorite className={`${commonStyles} fill-[red]`} />
        ) : (
          <MdOutlineFavoriteBorder
            className={`${commonStyles} fill-white/60`}
          />
        )}
      </div>
      {signinModalType ? (
        <SignInModal
          isOpen={!!signinModalType}
          handleClose={onClickSignModalOverlay}
          redirectTo={redirectUrl}
          type={signinModalType}
        />
      ) : null}
    </>
  );
}
