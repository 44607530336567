import { getRoutedPropertyById } from "@src/store/features/property/service";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getProposalPackages } from "@src/api/proposal";
import ProposalDetails from "./components/proposalDetails/ProposalDetails";
import PaymentCardDetails from "@src/components/paymentCardDetails/PaymentCardDetails";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import OverlayLoader from "@src/components/overlayLoader/OverlayLoader";
import { toast } from "react-toastify";
import { isFulfilled } from "@reduxjs/toolkit";

export default function CreateProposal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isReadyForPayment, setIsReadyForPayment] = useState(false);
  const [packageOptions, setPackageOptions] = useState([]);
  const [proposalFormData, setProposalFormData] = useState({
    propertyId: id,
    buyerMessage: "",
    timelineMin: 0,
    timelineMax: 1,
    packageType: null,
  });
  const [currentProposalData, setCurrentProposalData] = useState();
  const [paymentId, setPaymentId] = useState("");
  const stripePromise = useMemo(
    () => loadStripe(import.meta.env.VITE_STRIPE_API_KEY),
    [],
  );

  const property = useSelector((state) => state.property.currentRoutedProperty);
  const isLoadingPropertyDetails = useSelector(
    (state) => state.property.isLoadingRoutedProperty,
  );
  const loggedInUser = useSelector((state) => state.user.user);

  const selectedPackageLabel = useMemo(
    () =>
      packageOptions?.find(
        (item) => item?.id === currentProposalData?.package_type,
      )?.title,
    [packageOptions?.length, currentProposalData?.package_type],
  );

  const getPackageOptions = async () => {
    try {
      const response = await getProposalPackages();

      setPackageOptions(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getPropertyDetails = async () => {
    const response = await dispatch(getRoutedPropertyById(id));
    if (!isFulfilled(response)) {
      toast.error("Could not find that property");
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    if (!property?.id) return;

    if (
      loggedInUser?.id === property?.owner_id &&
      property?.id === Number(id)
    ) {
      toast.error("You own this property!");
      navigate("/", { replace: true });
      return;
    }

    /**
     * If the property is claimed, the owner id of the property and in the proposal must match
     * OR, if the property is not claimed and a paid proposal exists
     * redirect to that conversation
     */
    if (
      property?.owner_id === property?.latestPaidProposal?.owner_id ||
      (!property?.owner_id && property?.latestPaidProposal?.id)
    ) {
      navigate(`/conversation/${property?.latestPaidProposal?.id}`, {
        replace: true,
      });
      return;
    }
  }, [property?.owner_id, id, loggedInUser?.id]);

  useEffect(() => {
    if (property?.id !== id) getPropertyDetails();
    if (!packageOptions?.length) getPackageOptions();
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <section className="flex w-full flex-col items-center py-[74px]">
        {!isReadyForPayment ? (
          <ProposalDetails
            packageOptions={packageOptions}
            proposalFormData={proposalFormData}
            setProposalFormData={setProposalFormData}
            currentProposalData={currentProposalData}
            setCurrentProposalData={setCurrentProposalData}
            setIsReadyForPayment={setIsReadyForPayment}
            setPaymentId={setPaymentId}
          />
        ) : (
          <div
            className={`mx-6 ${!currentProposalData?.price ? "w-full max-w-[30rem] px-5" : ""}`}
          >
            <h3 className="mb-3 mt-4 flex items-end justify-between gap-3 font-attention text-neutral-800 md:mt-12">
              {/*<div className="full flex h-8 w-8 items-center justify-center rounded-full bg-primary-500 text-2xl text-white">*/}
              {/*  <span className="-translate-y-[0.2em]">4</span>*/}
              {/*</div>*/}
              <span className="ml-1 text-xl text-sage-800 md:text-2xl">
                Payment Details
              </span>
              <button
                onClick={() => setIsReadyForPayment(false)}
                className="mr-2 font-content text-sm font-semibold text-sage-700 underline"
              >
                Edit invitation
              </button>
            </h3>
            <PaymentCardDetails
              proposalId={currentProposalData?.id}
              paymentId={paymentId}
              price={currentProposalData?.price}
              setCurrentProposalData={setCurrentProposalData}
              currentAddedVoucher={currentProposalData?.voucher_code}
              selectedPackageLabel={selectedPackageLabel}
              propertyId={id}
            />
          </div>
        )}
      </section>
      {isLoadingPropertyDetails ? <OverlayLoader /> : null}
    </Elements>
  );
}
