import React from "react";

function PropertyProfileHeadline({
  property,
  className,
  shouldTruncate,
  inline,
}) {
  return (
    <h2
      style={{ overflowWrap: "anywhere" }}
      className={`${className} flex flex-wrap items-center gap-2`}
    >
      {property.owner_headline ? (
        <p
          className={`${shouldTruncate ? "truncate" : ""} ${inline ? "whitespace-pre" : ""}`}
        >
          {`${property.owner_headline}${inline ? ", " : ""}`}
        </p>
      ) : (
        <>
          <p
            className={`capitalize ${shouldTruncate ? "truncate" : ""}`}
          >{`${property?.address_line_1?.toLowerCase()}`}</p>
        </>
      )}
    </h2>
  );
}

export default PropertyProfileHeadline;
