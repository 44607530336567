// architecturalStyles.js
import artDecoImage from "../assets/architectural_styles/art_deco.png";
import bungalowImage from "../assets/architectural_styles/bungalow.webp";
import capeCodImage from "../assets/architectural_styles/capecod.webp";
import colonialImage from "../assets/architectural_styles/colonial.webp";
import contemporaryImage from "../assets/architectural_styles/contemporary.webp";
import craftsmanImage from "../assets/architectural_styles/craftsman.webp";
import creoleImage from "../assets/architectural_styles/creole.webp";
import dutchColonialImage from "../assets/architectural_styles/dutch_colonial.webp";
import federalImage from "../assets/architectural_styles/federal.webp";
import frenchProvincialImage from "../assets/architectural_styles/french_provincial.webp";
import georgianImage from "../assets/architectural_styles/georgian.webp";
import gothicRevivalImage from "../assets/architectural_styles/gothic_revival.webp";
import greekRevivalImage from "../assets/architectural_styles/greek_revival.webp";
import internationalImage from "../assets/architectural_styles/international.webp";
import italianateImage from "../assets/architectural_styles/italianate.webp";
import montereyImage from "../assets/architectural_styles/monterey.webp";
import nationalImage from "../assets/architectural_styles/national.webp";
import neoclassicalImage from "../assets/architectural_styles/neoclassical.webp";
import prairieImage from "../assets/architectural_styles/prairie.webp";
import puebloImage from "../assets/architectural_styles/pueblo.webp";
import queenAnneImage from "../assets/architectural_styles/queenanne.webp";
import ranchImage from "../assets/architectural_styles/ranch.webp";
import regencyImage from "../assets/architectural_styles/regency.webp";
import saltboxImage from "../assets/architectural_styles/saltbox.webp";
import secondEmpireImage from "../assets/architectural_styles/second_empire.webp";
import shedImage from "../assets/architectural_styles/shed.webp";
import shingleImage from "../assets/architectural_styles/shingle.webp";
import shotgunImage from "../assets/architectural_styles/shotgun.webp";
import spanishEclecticImage from "../assets/architectural_styles/spanish_eclectic.webp";
import splitLevelImage from "../assets/architectural_styles/split_level.webp";
import stickImage from "../assets/architectural_styles/stick.webp";
import tudorImage from "../assets/architectural_styles/tudor.webp";
import victorianImage from "../assets/architectural_styles/victorian.webp";
import iDontKnowImage from "../assets/architectural_styles/i_don't_know.png";
import otherImage from "../assets/architectural_styles/other.png";

const architecturalStyles = [
  { id: 1000, label: "Art Deco", image: artDecoImage, mapping: [2, 1000] },
  { id: 13, label: "Bungalow", image: bungalowImage, mapping: [13] },
  { id: 14, label: "Cape Cod", image: capeCodImage, mapping: [14] },
  {
    id: 7,
    label: "Colonial",
    image: colonialImage,
    mapping: [7, 51, 57, 64, 77],
  },
  { id: 8, label: "Contemporary", image: contemporaryImage, mapping: [8] },
  {
    id: 1001,
    label: "Craftsman",
    image: craftsmanImage,
    mapping: [13, 45, 1001],
  },
  { id: 1002, label: "Creole", image: creoleImage, mapping: [4, 6, 1002] },
  {
    id: 64,
    label: "Dutch Colonial",
    image: dutchColonialImage,
    mapping: [7, 64],
  },
  {
    id: 77,
    label: "Federal",
    image: federalImage,
    mapping: [7, 51, 57, 64, 77],
  },
  {
    id: 6,
    label: "French Provincial",
    image: frenchProvincialImage,
    mapping: [6, 60, 90],
  },
  { id: 110, label: "Georgian", image: georgianImage, mapping: [110] },
  {
    id: 61,
    label: "Gothic Revival",
    image: gothicRevivalImage,
    mapping: [61, 150],
  },
  {
    id: 1003,
    label: "Greek Revival",
    image: greekRevivalImage,
    mapping: [61, 1003],
  },
  {
    id: 1004,
    label: "International",
    image: internationalImage,
    mapping: [2, 8, 1004],
  },
  {
    id: 1005,
    label: "Italianate",
    image: italianateImage,
    mapping: [60, 61, 1005],
  },
  { id: 1006, label: "Monterey", image: montereyImage, mapping: [4, 1006] },
  { id: 1007, label: "National", image: nationalImage, mapping: [1007] },
  {
    id: 1008,
    label: "Neoclassical",
    image: neoclassicalImage,
    mapping: [61, 150, 1008],
  },
  { id: 1009, label: "Prairie", image: prairieImage, mapping: [2, 1009] },
  { id: 1010, label: "Pueblo", image: puebloImage, mapping: [4, 91, 1010] },
  { id: 1011, label: "Queen Anne", image: queenAnneImage, mapping: [18, 1011] },
  { id: 11, label: "Ranch", image: ranchImage, mapping: [20, 11, 27, 103] },
  { id: 1012, label: "Regency", image: regencyImage, mapping: [5, 60, 1012] },
  {
    id: 83,
    label: "Saltbox",
    image: saltboxImage,
    mapping: [83],
  },
  {
    id: 1013,
    label: "Second Empire",
    image: secondEmpireImage,
    mapping: [6, 60, 1013],
  },
  { id: 1014, label: "Shed", image: shedImage, mapping: [2, 8, 1014] },
  { id: 1015, label: "Shingle", image: shingleImage, mapping: [18, 1015] },
  { id: 153, label: "Shotgun", image: shotgunImage, mapping: [153] },
  {
    id: 4,
    label: "Spanish Eclectic",
    image: spanishEclecticImage,
    mapping: [4, 90, 91],
  },
  {
    id: 17,
    label: "Split Level",
    image: splitLevelImage,
    mapping: [16, 17, 125, 126],
  },
  { id: 1016, label: "Stick", image: stickImage, mapping: [18, 1016] },
  { id: 52, label: "Tudor", image: tudorImage, mapping: [52] },
  { id: 18, label: "Victorian", image: victorianImage, mapping: [18] },
  { id: 0, label: "I Don't Know", image: iDontKnowImage, mapping: [0] },
  { id: 12, label: "Other", image: otherImage, mapping: [12] },
  // THE BELOW ARE MISSING IMAGES
  // { id: 38, label: "Cabin", image: cabinImage, mapping: [38] },
  // { id: 85, label: "Chalet", image: chaletImage, mapping: [85] },
  // { id: 87, label: "Farm House", image: farmhouseImage, mapping: [87] },
  // { id: 152, label: "Tiny House", image: tinyHouseImage, mapping: [152] },
  // { id: 37, label: "Mansion", image: mansionImage, mapping: [37] },
  // { id: 36, label: "Townhouse", image: townhouseImage, mapping: [36] },
  // { id: 80, label: "Loft", image: loftImage, mapping: [80] },
  // { id: 81, label: "Penthouse", image: penthouseImage, mapping: [81] }
];

export default architecturalStyles;
