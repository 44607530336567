import { Navigate } from "react-router-dom";
import { getAuthTokenFromStorage } from "@src/utils/misc";
import { useLayoutEffect, useState } from "react";

const LoggedInRedirect = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useLayoutEffect(() => {
    const checkAuth = async () => {
      const token = await getAuthTokenFromStorage();
      setIsLoggedIn(!!token);
    };

    checkAuth();
  }, []);

  if (isLoggedIn === null) return null;

  // If user is logged in and trying to access /signin, redirect to root
  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  // Otherwise, show the signin page
  return children;
};

export default LoggedInRedirect;
