import React from "react";
import { MdHome } from "react-icons/md";
import { motion } from "framer-motion";

function Spinner({ label = "One sec..." }) {
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{
        duration: 0.3,
        damping: 30,
        ease: "easeOut",
        delay: 0.1,
      }}
      className="flex flex-col items-center gap-6"
    >
      <div className="relative">
        <div className="h-14 w-14 animate-spin rounded-full border-4 border-neutral-300 border-r-primary-500"></div>
      </div>

      <span className="animate-pulse text-sm text-neutral-500">{label}</span>
    </motion.div>
  );
}

export default Spinner;
